<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 mt-5">
                <img src="assets/img/404-error.png" class="img-404 centrar">
                <h3 class="text-center text-uppercase mt-3">Página no encontrada</h3>
                <h5 class="text-center mt-3 mb-5">Lo sentimos, esta página no existe o no esta disponible</h5>
                <div class="d-flex justify-content-center">
                    <button class="btn btn-gray btn-lg" type="button" (click)='onReturnClicked()'>
                        {{localization.returnBeginning}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
