import { Observable } from 'rxjs';
import {
  GetFileMailBoxRequestDTO,
  GetFileMailBoxResponseDTO,
  ListCasesDTO,
  ResolveNotesRequestDTO,
  ResolveNotesResponseDTO,
  SendNotesRequestDTO,
  SendNotesResponseDTO,
  UploadFilesRequestDTO,
  UploadFilesResponseDTO,
  ReplyNotesResponseDTO,
  ReplyNotesRequestDTO,
  DigitalNotesRequestDTO,
  DigitalNotesResponseDTO
} from 'src/app/data/dto/MailBoxNotesDTO';

export abstract class MailBoxRepository {
  abstract sendNotesMailBox(
    request: SendNotesRequestDTO
  ): Observable<SendNotesResponseDTO>;
  abstract uploadFilesMailBox(
    request: UploadFilesRequestDTO
  ): Observable<UploadFilesResponseDTO>;
  abstract listCasesMailBox(): Observable<ListCasesDTO>;
  abstract getFileMailBox(
    request: GetFileMailBoxRequestDTO
  ): Observable<GetFileMailBoxResponseDTO>;
  abstract resolveNotes(
    request: ResolveNotesRequestDTO
    ): Observable<ResolveNotesResponseDTO>;
  abstract replyNotes(
    request: ReplyNotesRequestDTO
    ): Observable<ReplyNotesResponseDTO>;
  abstract digitalNotes(
    request: DigitalNotesRequestDTO
    ): Observable<DigitalNotesResponseDTO>;
}
