import { Observable } from 'rxjs';
import {
  AddComisionRequestDTO,
  AddComisionResponseDTO,
  ListAchievementsAdminRequestDTO,
  ListAchievementsAdminResponseDTO,
  ListAchievementsRequestDTO,
  ListAchievementsResponseDTO,
  ListInventoryAdminRequestDTO,
  ListInventoryAdminResponseDTO,
  ListInventoryRequestDTO,
  ListInventoryResponseDTO,
  PhotosInventoryRequestDTO,
  PhotosInventoryResponseDTO,
  ProgramScreenRequestDTO,
  ProgramScreenResponseDTO,
  RegisterInventoryRequestDTO,
  RegisterInventoryResponseDTO,
  UpdateAvailableRequestDTO,
  UpdateAvailableResponseDTO,
  UploadImgInventoryRequestDTO,
  UploadImgInventoryResponseDTO,
  CampaignOkeyRequestDTO,
  CampaignOkeyResponseDTO,
  ScheduleScreenRequestDTO,
  ScheduleScreenResponseDTO,
  ListScheduleScreenAdminRequestDTO,
  ListScheduleScreenAdminResponseDTO,
  ListScheduleScreenRequestDTO,
  ListScheduleScreenResponseDTO,
  PayStripeRequestDTO,
  PayStripeResponseDTO,
  UpdatePriceRequestDTO,
  UpdatePriceResponseDTO,
} from 'src/app/data/dto/InventoryDTO';
import {
  AddSpacesAvailableRequestDTO,
  AddSpacesAvailableResponseDTO,
  OKSpacesAvailableRequestDTO,
  OKSpacesAvailableResponseDTO,
  SearchSpacesAvailableRequestDTO,
  SearchSpacesAvailableResponseDTO,
} from 'src/app/data/dto/SpacesAvailableDTO';

export abstract class InventoryRepository {
  abstract registerInventoryDigital(
    request: RegisterInventoryRequestDTO
  ): Observable<RegisterInventoryResponseDTO>;
  abstract listInventoryDigital(
    request: ListInventoryRequestDTO
  ): Observable<ListInventoryResponseDTO>;
  abstract uploadImgInventoryDigital(
    request: UploadImgInventoryRequestDTO
  ): Observable<UploadImgInventoryResponseDTO>;
  abstract updateAvailableInventory(
    request: UpdateAvailableRequestDTO
  ): Observable<UpdateAvailableResponseDTO>;
  abstract listInventoryAdmin(
    id: ListInventoryAdminRequestDTO
  ): Observable<ListInventoryAdminResponseDTO>;
  abstract addComisionScreen(
    request: AddComisionRequestDTO
  ): Observable<AddComisionResponseDTO>;
  abstract addSpacesAvailable(
    request: AddSpacesAvailableRequestDTO
  ): Observable<AddSpacesAvailableResponseDTO>;
  abstract searchSpacesAvailable(
    request: SearchSpacesAvailableRequestDTO
  ): Observable<SearchSpacesAvailableResponseDTO>;
  abstract searchSpacesAdmin(
    request: SearchSpacesAvailableRequestDTO
  ): Observable<SearchSpacesAvailableResponseDTO>;
  abstract okSpacesAvailable(
    request: OKSpacesAvailableRequestDTO
  ): Observable<OKSpacesAvailableResponseDTO>;
  abstract programScreen(
    request: ProgramScreenRequestDTO
  ): Observable<ProgramScreenResponseDTO>;
  abstract getMyAchievements(
    request: ListAchievementsRequestDTO
  ): Observable<ListAchievementsResponseDTO>;
  abstract getMyAchievementsAdmin(
    request: ListAchievementsAdminRequestDTO
  ): Observable<ListAchievementsAdminResponseDTO>;
  abstract getPhotosInventory(
    request: PhotosInventoryRequestDTO
  ): Observable<PhotosInventoryResponseDTO>;
  abstract okeyCampaign(
    request: CampaignOkeyRequestDTO
    ): Observable<CampaignOkeyResponseDTO>;
  abstract scheduleScreen(
    request: ScheduleScreenRequestDTO
    ): Observable<ScheduleScreenResponseDTO>;
  abstract listScheduleScreen(
    request: ListScheduleScreenRequestDTO
    ): Observable<ListScheduleScreenResponseDTO>;
  abstract listScheduleScreenAdmin(
    request: ListScheduleScreenAdminRequestDTO
    ): Observable<ListScheduleScreenAdminResponseDTO>;
  abstract payStripe(
    request: PayStripeRequestDTO
    ): Observable<PayStripeResponseDTO>;
  abstract UpdatePrice(
    request: UpdatePriceRequestDTO
  ): Observable<UpdatePriceResponseDTO>;
}
