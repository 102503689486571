import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginResponseDTO } from 'src/app/data/dto/LoginDTO';

@Injectable({ providedIn: 'root' })
export class AuthService {
  idUpdateRelease: number;

  private currentUserSubject: BehaviorSubject<LoginResponseDTO>;
  public currentUser: Observable<LoginResponseDTO>;

  get isLoggedIn(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginResponseDTO {
    return this.currentUserSubject.value;
  }

  constructor(private router: Router) {
    this.currentUserSubject = new BehaviorSubject<LoginResponseDTO>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(user): any {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(JSON.parse(JSON.stringify(user)));
    return user;
  }

  logout(): any {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
