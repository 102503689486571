export abstract class Localization {
    appTitle: string;
    appCredentialError: string;
    page404Title: string;
    page404Text: string;
    userRequired: string;
    passwordRequired: string;
    registerConfirmPassword: string;
    inputRequired: string;
    registerPhoneRequired: string;
    registerMinPassword: string;
    registerEmailRequired: string;
    validEmailRequired: string;
    registerConfirmpassworRequired: string;
    matchPassword: string;
    comboRequired: string;
    searchFilters: string;
    logrosPantalla: string;
    searchByMonth: string;
    logrosBusquedaCiudad: string;
    client: string;
    screenCommissions: string;
    btnSearch: string;
    downloadPdf: string;
    dowloadExcel: string;
    logrosTdSinInformacion: string;
    addRegister: string;
    continue: string;
    myAccount: string;
    selectAnOption: string;
    physicalPerson: string;
    register: string;
    moralPerson: string;
    loading: string;
    physicalPersonRegister: string;
    moralPersonRegister: string;
    documents: string;
    physicalPersonUploadFiles: string;
    invalidFile: string;
    selectFile: string;
    UploadFilesInFormat: string;
    cancel: string;
    confirmation: string;
    dataCorrect: string;
    personMoralUploadFilesCorrespondig: string;
    campaigns: string;
    myCampaigns: string;
    registerUpdated: string;
    userId: string;
    userName: string;
    campaign: string;
    authorizedOrRejected: string;
    close: string;
    authorizationTime: string;
    spot: string;
    closed: string;
    download: string;
    noInformation: string;
    closeThisCampaign: string;
    accept: string;
    closedCampaign: string;
    bug: string;
    doesNotExistTheFile: string;
    inventoriOfScreen: string;
    searchYourScreens: string;
    medios: string;
    medio: string;
    medioType: string;
    select: string;
    priceRange: string;
    country: string;
    state: string;
    municipality: string;
    clean: string;
    acept: string;
    orchestrateMyCampaigns: string;
    name: string;
    available: string;
    program: string;
    unitPrice: string;
    total: string;
    no: string;
    yes: string;
    scheduleCampaign: string;
    address: string;
    coordinates: string;
    photo: string;
    viewPhotos: string;
    seeContractedScreens: string;
    selectDate: string;
    initialDate: string;
    addInitialDate: string;
    addInitialHour: string;
    addFinalHour: string;
    finalDate: string;
    addFinalDate: string;
    selectHour: string;
    startTimeInterval: string;
    endTimeInterval: string;
    registerSavedSuccessfully: string;
    programCampaignCorrect: string;
    paymentOrInvoice: string;
    invoice: string;
    abonate: string;
    abonarse: string;
    checkIn: string;
    searchInvoices: string;
    invoices: string;
    monthInvoice: string;
    myInvoice: string;
    location: string;
    month: string;
    ok: string;
    correctInvoice: string;
    yesAccept: string;
    invoiceByMonth: string;
    invoiceNumber: string;
    importe: string;
    myNotes: string;
    seeCases: string;
    notesAndSuggestions: string;
    inputName: string;
    lastName: string;
    inputLastName: string;
    email: string;
    inputEmail: string;
    invalidEmail: string;
    telephone: string;
    inputTelephone: string;
    invalidTelephone: string;
    comments: string;
    inputMessage: string;
    includeDocuments: string;
    uploadFiles: string;
    send: string;
    messageSent: string;
    inContact: string;
    cases: string;
    listCases: string;
    clientNumber: string;
    mail: string;
    comment: string;
    attachedFile: string;
    attachedFiles: string;
    solve: string;
    action: string;
    downloadFiles: string;
    noFiles: string;
    write: string;
    resolvedMessage: string;
    messageSuccesful: string;
    myInventory: string;
    screenList: string;
    pinScreen: string;
    digitalName: string;
    annualPrice: string;
    commission: string;
    SuggestedPixel: string;
    screenMeasurements: string;
    afluencia: string;
    screenUpdate: string;
    myAchievements: string;
    ingresos: string;
    deAcuerdo: string;
    myPayments: string;
    digitalPartner: string;
    screen: string;
    invoiceStatus: string;
    invalidFileType: string;
    fileUploadedSuccessfully: string;
    spacesAvailable: string;
    capture: string;
    estimatedPrice: string;
    addSpacesAvailable: string;
    screenshots: string;
    nameMedio: string;
    latitude: string;
    length: string;
    save: string;
    addedAvailableSpace: string;
    users: string;
    registeredUsers: string;
    registerUser: string;
    userDeleted: string;
    numberUsername: string;
    profile: string;
    cellPhoneNumber: string;
    actions: string;
    edit: string;
    remove: string;
    yesDelete: string;
    userProfile: string;
    userMenu: string;
    selectProfile: string;
    obligatoryField: string;
    password: string;
    return: string;
    registeredUser: string;
    updateUser: string;
    userUpdatedSuccessfully: string;
    reportsAdmin: string;
    ingresosMonthly: string;
    campaignsByScreens: string;
    campaignsForBars: string;
    monthlyStickers: string;
    clientData: string;
    dataToInvoice: string;
    reasonSocial: string;
    addReason: string;
    rfcIdentifier: string;
    addRfc: string;
    invalidCharacters: string;
    tip: string;
    dataForProof: string;
    type: string;
    dateOfCapture: string;
    paymentConditions: string;
    paymentMethod: string;
    wayToPay: string;
    use: string;
    coin: string;
    exchangeRate: string;
    retentionIva: string;
    retentionIsr: string;
    factor: string;
    export: string;
    detail: string;
    concept: string;
    presentation: string;
    satProduct: string;
    cost: string;
    amount: string;
    iva: string;
    pdfInvoice: string;
    xmlInvoice: string;
    invoiceGenerated: string;
    downloadableFiles: string;
    numberDigitalPartner: string;
    grossIncome: string;
    incomeCampaigns: string;
    grandTotal: string;
    mediaPoster: string;
    contractedScreens: string;
    media: string;
    outstandingScreens: string;
    startDateAndTime: string;
    endDateAndTime: string;
    contract: string;
    goToPayment: string;
    contractScreen: string;
    yesContract: string;
    cancelContract: string;
    yesSure: string;
    continuePayment: string;
    digitalUtility: string;
    utilitySs: string;
    entry: string;
    createCampaign: string;
    campaignName: string;
    uploadSpot: string;
    uploadVideos: string;
    maximumFile: string;
    selectYourFile: string;
    loadMoreCampaigns: string;
    endCapture: string;
    myScreens: string;
    historyOfSticker: string;
    screenOne: string;
    every: string;
    january: string;
    february: string;
    march: string;
    april: string;
    may: string;
    user: string;
    screenType: string;
    iAmADigitalPartner: string;
    stickers: string;
    achievements: string;
    utility: string;
    forgottenPassword: string;
    newAccount: string;
    logIn: string;
    recoverPassword: string;
    recoveryMessage: string;
    mailSentSuccessfully: string;
    iAmAUser: string;
    confirmPassword: string;
    registerAccount: string;
    accountCreated: string;
    tracing: string;
    addScreens: string;
    showLocation: string;
    pixelPitch: string;
    visitors: string;
    images: string;
    minimumOnePhoto: string;
    invalidPhoto: string;
    uploadPhotos: string;
    typeOfFiles: string;
    selectYourFiles: string;
    uploadAnotherPhoto: string;
    uploadInvoices: string;
    invalidFileTypeXml: string;
    savedFile: string;
    uploadInvoice: string;
    includFile: string;
    nameFile: string;
    upload: string;
    returnBeginning: string;
    dashboardMessage: string;
    welcome: string;
    monetizeInventory: string;
    boostCampaigns: string;
    dashboardCampaigns: string;
    dashboardState: string;
    dashboardMunicipality: string;
    localities: string;
    campaignDate: string;
    campaignTime: string;
    campaignList: string;
    campaignType: string;
    datendTime: string;
    payment: string;
    pendiente: string;
    paid: string;
    orchestrate: string;
    authorized: string;
    rejected: string;
    requiredCampaignName: string;
    mp4Format: string;
    selectYourFileMp4: string;
    campaignCreated: string;
    myReports: string;
    searchByCountry: string;
    searchByState: string;
    screeName: string;
    stiker: string;
    subtotal: string;
    monthlyBehavior: string;
    montoPagar: string;
    cardData: string;
    cardholder: string;
    cardSpecifications: string;
    cardAddress: string;
    street: string;
    number: string;
    city: string;
    suburb: string;
    pay: string;
    errorEnterCardNumber: string;
    reportMonth: string;
    screens: string;
    campaignOne: string;
    campaignTwo: string;
    campaignThree: string;
    totalCampaigns: string;
    screenCampaigns: string;
    digitalPartnerOne: string;
    digitalPartnerTwo: string;
    generalIngresos: string;
    screensForMonth: string;
    historicalStates: string;
    reportsSocio: string;
    reportCampaignsMonth: string;
    reportIngresos: string;
    reportStickersMonth: string;
    reportStateMonth: string;
    reportCampaignHistorical: string;
    reportIngresosHistorical: string;
    reportStickersHistorical: string;
    minute: string;
    hour: string;
    day: string;
    week: string;
    signOff: string;
    myProfile: string;
    support: string;
    reports: string;
    reportUser: string;
    historyOfCampaigns: string;
    reportCampaignMonth: string;
    campaignsByScreensHistorical: string;
    inversion: string;
    campaignForMonth: string;
    seconds: string;
    spotScreen: string;
    reportInversion: string;
    stickersScreensMonth: string;
    reportsUser: string;
    historyInversion: string;
    historyOfStickerScreen: string;
    contractedTime: string;
    horas: string;
    ValidationVideo: string;
}
export class SpanishLocalization implements Localization {
    appTitle = 'gs-slicks-sticky';
    reportUser = 'Reportes usuario';
    seconds = 'Segundos';
    reportsUser = 'Reportes de usuario';
    spotScreen = 'Spot por pantalla';
    stickersScreensMonth = 'Stickers por pantalla por mes';
    ValidationVideo = 'Vuelve a seleccionar un nuevo archivo, "el tamaño máximo es de 90 MB".';
    historyOfStickerScreen = 'Histórico de sticker por campañas';
    historyInversion = 'Histórico de inversión por pantalla';
    reportInversion = 'Reporte de inversión por mes';
    historyOfCampaigns = 'Histórico de campañas por estado por pantallas';
    reportCampaignMonth = 'Reporte de campañas por mes';
    inversion = 'Inversión';
    contractedTime = 'Tiempo contratado por días';
    campaignForMonth = 'Campañas por estado al mes';
    campaignsByScreensHistorical = 'Campañas por pantalla en histórico';
    myProfile = 'Mi perfil';
    support = 'Soporte';
    signOff = 'Cerrar sesión';
    reports = 'Reportes';
    ok = 'OK';
    no = 'No';
    yes = 'Si';
    use = 'Uso';
    iva = 'IVA';
    day = 'Día';
    may = 'Mayo';
    hour = 'Hora';
    horas = 'Horas';
    spot = 'Spot';
    bug = 'Error';
    type = 'Tipo';
    month = 'Mes';
    pay = 'PAGAR';
    mail = 'Email';
    photo = 'Foto';
    cost = 'Costo';
    week = 'Semana';
    edit = 'Editar';
    coin = 'Moneda';
    name = 'Nombre';
    total = 'Total';
    cases = 'Casos';
    medio = 'Medio';
    send = 'Envíar';
    april = 'Abril';
    every = 'Todos';
    march = 'Marzo';
    paid = 'Pagado';
    city = 'Ciudad';
    media = 'VMEDIA';
    user = 'Usuario';
    close = 'Cerrar';
    country = 'País';
    save = 'Guardar';
    state = 'Estado';
    upload = 'Subir';
    street = 'Calle';
    payment = 'Pago';
    number = 'Numero';
    january = 'Enero';
    medios = 'Medios';
    clean = 'Limpiar';
    acept = 'Aceptar';
    action = 'Acción';
    factor = 'Factor';
    stiker = 'Stiker';
    minute = 'Minuto';
    solve = 'Resolver';
    closed = 'Cerrado';
    users = 'Usuarios';
    profile = 'Perfil';
    suburb = 'Colonia';
    images = 'Imágenes';
    return = 'Regresar';
    invoice = 'Factura';
    cancel = 'Cancelar';
    client = 'Cliente:';
    abonate = 'Abonate';
    remove = 'Eliminar';
    importe = 'Importe';
    amount = 'Cantidad';
    utility = 'Utilidad';
    screen = 'Pantalla';
    btnSearch = 'Buscar';
    loading = 'Cargando';
    campaign = 'Campaña';
    checkIn = 'Facturar';
    length = 'Longitud:';
    actions = 'Acciones';
    february = 'Febrero';
    concept = 'Concepto';
    address = 'Dirección';
    latitude = 'Latitud:';
    invoices = 'Facturas';
    abonarse = 'Abonarse';
    accept = 'Si, acepto';
    userId = 'ID usuario';
    program = 'Programar';
    ingresos = 'Ingresos';
    stickers = 'Stickers';
    subtotal = 'Subtotal';
    screens = 'Pantallas';
    rejected = 'Rechazada';
    export = 'Exportación';
    comment = 'Comentario';
    telephone = 'Teléfono';
    seeCases = 'Ver casos';
    download = 'Descargar';
    continue = 'Continuar';
    register = 'Registrar';
    campaigns = 'Campañas';
    location = 'Ubicación';
    lastName = 'Apellidos';
    contract = 'Contratar';
    password = 'Contraseña';
    afluencia = 'Afluencia';
    myAccount = 'Mi cuenta';
    select = '-Seleccione-';
    commission = 'Comisión';
    achievements = 'Logros';
    entry = 'Ingreso bruto';
    pendiente = 'Pendiente';
    welcome = '¡Bienvenido!';
    screenOne = 'Pantalla 1';
    deAcuerdo = 'De acuerdo';
    viewPhotos = 'VER FOTOS';
    available = 'Disponible';
    documents = 'Documentos';
    myPayments = 'MIS PAGOS';
    logIn = 'Iniciar sesión';
    authorized = 'Autorizada';
    utilitySs = 'Utilidad SS';
    yesAccept = 'Si, aceptar';
    orchestrate = 'Orquestar';
    campaignOne = 'Campaña 1';
    campaignTwo = 'Campaña 2';
    goToPayment = 'Ir al pago';
    yesDelete = 'Si, eliminar';
    finalDate = 'Fecha de fin';
    municipality = 'Municipio';
    myInvoice = 'Mis facturas';
    pinScreen = 'PIN Pantalla';
    wayToPay = 'Forma de Pago';
    pixelPitch = 'Pixel pitch';
    localities = 'Localidades';
    dashboardState = 'Estados';
    datendTime = 'Fecha y hora';
    campaignThree = 'Campaña 3';
    noFiles = 'No hay archivos';
    medioType = 'Tipo de medio';
    coordinates = 'Coordenadas';
    satProduct = 'Producto SAT';
    mediaPoster = 'PosterMedia';
    myScreens = 'Mis pantallas';
    iAmAUser = 'Soy un usuario';
    nameFile = 'nombre_archivo';
    montoPagar = 'Monto a pagar';
    yesSure = 'Si, estoy seguro';
    myCampaigns = 'Mis campañas';
    email = 'Correo electrónico';
    listCases = 'Lista de casos';
    write = 'Redactar respuesta';
    grandTotal = 'Total general';
    uploadVideos = 'Subir video';
    reasonSocial = 'Razón Social';
    nameMedio = 'Nombre de medio';
    capture = 'Capturar pantalla';
    myAchievements = 'Mis logros';
    myInventory = 'Mi inventario';
    confirmation = 'Confirmación';
    logrosPantalla = 'Pantalla:';
    moralPerson = 'Persona moral';
    downloadPdf = 'Descargar PDF';
    unitPrice = 'Precio unitario';
    uploadFiles = 'Subir archivo';
    presentation = 'Presentación';
    pdfInvoice = 'Factura en PDF';
    xmlInvoice = 'Factura en XML';
    retentionIva = 'Retención IVA';
    annualPrice = 'Precio mensual';
    userName = 'Nombre de usuario';
    detail = 'Detalle comprobante';
    retentionIsr = 'Retención ISR';
    initialDate = 'Fecha de inicio';
    screenType = 'Tipo de pantalla';
    grossIncome = 'Ingresos brutos';
    exchangeRate = 'Tipo de cambio';
    includFile = 'Adjuntar archivo';
    uploadInvoice = 'Subir factura';
    cardData = 'Datos de la tarjeta';
    endCapture = 'Finalizar captura';
    dowloadExcel = 'Descargar Excel';
    selectHour = 'Seleccionar hora:';
    monthInvoice = 'Mes a facturar:';
    attachedFile = 'Archivo adjunto';
    createCampaign = 'Crear campaña';
    paymentMethod = 'Método de Pago';
    clientData = 'Datos del cliente';
    addRfc = 'Debes ingresar tu RFC';
    campaignTime = 'Hora de campaña';
    screeName = 'Nombre de pantalla';
    campaignType = 'Tipo de campaña';
    digitalPartner = 'Digital socio';
    physicalPerson = 'Persona física';
    selectDate = 'Seleccionar fecha:';
    screenList = 'Lista de pantallas';
    updateUser = 'Actualizar usuario';
    uploadInvoices = 'Subir facturas';
    campaignDate = 'Fecha de campaña';
    dateOfCapture = 'Fecha de factura';
    selectFile = 'Seleccionar archivo';
    searchInvoices = 'Buscar facturas';
    clientNumber = 'Número de cliente';
    registerUser = 'Registrar usuario';
    campaignName = 'Nombre de campaña';
    uploadPhotos = 'Subir fotografías';
    showLocation = 'Mostrar ubicación';
    campaignList = 'Lista de campañas';
    rfcIdentifier = 'RFC/Identificador';
    attachedFiles = 'Archivos adjuntos';
    invoiceNumber = 'Número de factura';
    searchByMonth = 'Búsqueda por mes:';
    myNotes = 'Mis notas y sugerencias';
    maximumFile = 'Archivo máximo 90MB';
    addScreens = 'Agrega tus pantallas';
    yesContract = 'Si, quiero contratar';
    screenshots = 'Captura de pantallas';
    digitalName = 'Nombre digital socio';
    downloadFiles = 'Descargar archivos';
    userMenu = 'Menú asignado - Usuario';
    numberUsername = 'Número de usuario';
    registerAccount = 'Registrar cuenta';
    visitors = 'Afluencia de visitantes';
    dashboardMunicipality = 'Municipios';
    totalCampaigns = 'Total de campañas';
    continuePayment = 'Continuar al pago';
    newAccount = 'Registrar nueva cuenta';
    cellPhoneNumber = 'Número de celular';
    page404Title = 'Página no encontrada';
    searchFilters = 'Filtros de búsqueda';
    invoiceStatus = 'Estado de facturas:';
    userProfile = 'Perfilado de usuarios';
    obligatoryField = 'Campo obligatorio';
    dataToInvoice = 'Datos para facturar';
    digitalPartnerTwo = 'Digital socio 2';
    digitalPartnerOne = 'Digital socio 1';
    uploadSpot = 'Subir spot publicitario';
    scheduleCampaign = 'Programar campaña';
    paymentOrInvoice = 'Abonate o factura';
    inputName = 'Debes ingresar un nombre';
    endDateAndTime = 'Fecha y hora de fin';
    returnBeginning = 'Regresar al inicio';
    searchByCountry = 'Búsqueda por país:';
    searchByState = 'Búsqueda por estado:';
    dataForProof = 'Datos para comprobante';
    reportsAdmin = 'Reportes administrador';
    SuggestedPixel = 'Pixel pitch sugerido';
    uploadAnotherPhoto = 'Cargar otra foto';
    myReports = 'Mis reportes e históricos';
    cardAddress = 'Dirección de la tarjeta';
    selectYourFile = 'Selecciona tu archivo';
    includeDocuments = 'Adjuntar documentos';
    selectAnOption = 'Selecciona una opción';
    spacesAvailable = 'Espacios disponibles';
    messageSent = 'Se ha mandado tu mensaje';
    registeredUsers = 'Usuarios registrados';
    recoverPassword = 'Recuperar contraseña';
    typeOfFiles = 'Archivos jpg, jpeg y png';
    confirmPassword = 'Confirmar contraseña:';
    numberDigitalPartner = 'N° digital socio';
    digitalUtility = 'Utilidad digital socio';
    loadMoreCampaigns = 'Cargar más campañas';
    searchYourScreens = 'Busca tus pantallas';
    paymentConditions = 'Condiciones de Pago';
    boostCampaigns = 'Potencía tus campañas.';
    screenMeasurements = 'Medidas de pantalla';
    inputLastName = 'Debes ingresar apellidos';
    authorizationTime = 'Hora de autorización';
    priceRange = 'Intervalo de precio mensual';
    inputMessage = 'Debes ingresar un mensaje';
    estimatedPrice = 'Precio estimado mensual';
    monthlyBehavior = 'Comportamiento mensual';
    reportsSocio = 'Reportes de digital socio';
    selectYourFiles = 'Selecciona tus archivos';
    startDateAndTime = 'Fecha y hora de inicio';
    notesAndSuggestions = 'Notas y sugerencias';
    inputRequired = 'Este campo es obligatorio';
    endTimeInterval = 'Fin intervalo de tiempo';
    contractedScreens = 'Pantallas Contratadas';
    iAmADigitalPartner = 'Soy un digital socio';
    dashboardCampaigns = 'Dashboard de campañas';
    invoiceByMonth = 'Facturas emitidas por mes';
    comments = 'Dejanos tus dudas y comentarios';
    userDeleted = '¡Se ha eliminado al usuario!';
    addReason = 'Debes ingresar tu razón social';
    savedFile = 'El archivo se guardo con éxito';
    campaignsByScreens = 'Campañas por pantallas';
    selectProfile = 'Debes seleccionar un perfil';
    addFinalHour = 'Debes agregar una hora final';
    closedCampaign = '¡La campaña se ha cerrado!';
    inventoriOfScreen = 'Inventario de pantallas';
    inputTelephone = 'Debes ingresar un teléfono';
    invoiceGenerated = 'Se ha generado tu factura';
    invalidTelephone = 'Debes ingresar 10 dígitos';
    addFinalDate = 'Debes agregar una fecha final';
    matchPassword = 'Las contraseñas no coinciden';
    comboRequired = 'Debes seleccionar una opción';
    screenCommissions = 'Comisiones de pantallas:';
    selectYourFileMp4 = 'Selecciona tu archivo mp4';
    forgottenPassword = '¿Olvidaste tu contraseña?';
    resolvedMessage = 'Se ha resuelto este mensaje';
    authorizedOrRejected = 'Autorizada o rechazada';
    logrosBusquedaCiudad = 'Búsqueda por ciudad:';
    reportStateMonth = 'Reporte por estado mensual';
    registeredUser = '¡Se ha registrado al usuario!';
    startTimeInterval = 'Inicia intervalo de tiempo';
    monetizeInventory = 'Moneticemos tu inventario.';
    addRegister = 'Se ha agregado registro como "OK"';
    invalidFile = '¡El tipo de archivo no es válido!';
    addSpacesAvailable = 'Agregar espacio disponible';
    orchestrateMyCampaigns = 'Orquestar mis campañas';
    minimumOnePhoto = 'Debes subir al menos una foto.';
    monthlyStickers = 'Stickers mensuales por campaña';
    noInformation = 'No hay información para mostrar.';
    addInitialDate = 'Debes agregar una fecha inicial';
    seeContractedScreens = 'Ver Pantallas Contratadas';
    invalidCharacters = 'Debes ingresar 13 caracteres';
    campaignsForBars = 'Campañas por pantallas barras';
    campaignCreated = '¡La campaña se creo con éxito!';
    addInitialHour = 'Debes agregar una hora de inicio';
    registerUpdated = '¡El registro se ha actualizado!';
    moralPersonRegister = 'Registro para persona moral';
    registerPhoneRequired = 'Debes capturar 10 dígitos';
    inputEmail = 'Debes ingresar un correo electrónico';
    screenCampaigns = 'Campañas por pantallas histórico';
    ingresosMonthly = 'Ingresos mensuales por pantallas';
    tracing = 'Para continuar, necesitas iniciar sesión';
    downloadableFiles = 'Ya puedes descargar tus archivos';
    validEmailRequired = 'Debes ingresar un correo válido';
    historyOfSticker = 'Histórico de sticker por pantalla';
    reportMonth = 'Reporte de campañas al mes por pantalla';
    registerMinPassword = 'Deben ser al menos 6 caracteres';
    physicalPersonRegister = 'Registro para persona física';
    screenUpdate = 'Se actualizó correctamente la pantalla';
    mailSentSuccessfully = '¡Correo enviado correctamente!';
    reportIngresos = 'Reporte ingresos por pantalla por mes';
    registerConfirmPassword = 'Las contraseñas no coinciden';
    inContact = 'En breve nos pondremos en contacto contigo';
    accountCreated = '¡Se ha creado tu cuenta correctamente!';
    passwordRequired = 'Debes ingresar una contraseña valida';
    dataCorrect = '¡Se han guardado correctamente los datos!';
    correctInvoice = '¿Estás seguro de aceptar esta factura?';
    incomeCampaigns = 'Campañas por pantallas ingresos brutos';
    logrosTdSinInformacion = 'No hay información para mostrar';
    addedAvailableSpace = 'Se ha agregado un espacio disponible';
    userRequired = 'Debes ingresar un correo electrónico válido';
    invalidEmail = 'Debes ingresar un correo electrónico válido';
    outstandingScreens = 'Lista de pantallas pendientes de pago';
    requiredCampaignName = 'Debes ingresar un nombre de campaña';
    generalIngresos = 'Reporte de ingresos generales históricos';
    screensForMonth = 'Reporte de ingresos por pantalla por mes';
    reportStickersMonth = 'Reporte stickers al mes por pantalla';
    reportCampaignsMonth = 'Reporte campañas al mes por pantalla';
    contractScreen = '¿Estás de acuerdo en contratar la pantalla';
    cardholder = 'El nombre debe ser el del titular de la tarjeta';
    registerEmailRequired = 'Debes ingresar un correo electrónico';
    closeThisCampaign = '¿Estás de acuerdo de cerrar esta campaña?';
    registerConfirmpassworRequired = 'Debes confirmar tu contraseña';
    messageSuccesful = 'Se ah enviado el mensaje satisfactoriamente';
    reportIngresosHistorical = 'Reporte ingresos generales histórico';
    appCredentialError = 'El usuario y/o contraseña son incorrectos.';
    fileUploadedSuccessfully = 'Se ha cargado correctamente el archivo';
    programCampaignCorrect = 'Se ha programado correctamente la campaña';
    errorEnterCardNumber = '¡Error! debes ingresar tu numero de tarjeta';
    historicalStates = 'Reporte de participación por estados históricos';
    reportCampaignHistorical = 'Reporte campañas por pantallas histórico';
    doesNotExistTheFile = '¡Lo sentimos, no pudimos encontrar el archivo!';
    registerSavedSuccessfully = 'Se ha guardado el registro correctamente';
    reportStickersHistorical = 'Reporte de stickers por pantalla histórico';
    page404Text = 'Lo sentimos, esta página no esta disponible o no existe';
    userUpdatedSuccessfully = '¡Se ha actualizado al usuario correctamente!';
    tip = 'Tip: La Razón Social debe ser igual a la  registrada ante el SAT';
    mp4Format = 'El tipo de archivo no es válido, solo se acepta formato mp4';
    cancelContract = '¿Estás seguro en cancelar la contratacion de la pantalla?';
    physicalPersonUploadFiles = 'Debes subir los tres archivos correspondientes.';
    cardSpecifications = 'Numero, vencimiento, CVV de la tarjeta y Codigo Postal';
    invalidFileType = '¡El tipo de archivo no es válido!, Debes subir archivos PDF';
    UploadFilesInFormat = 'Recuerda subir tus documentos en formato PDF, PNG y JPG.';
    invalidFileTypeXml = '¡El tipo de archivo no es válido!, Debes subir archivo XML';
    personMoralUploadFilesCorrespondig = 'Debes subir todos los archivos correspondientes.';
    invalidPhoto = 'El tipo de archivo no es válido, solo se aceptan archivos jpg, jpeg y png';
    recoveryMessage = 'Se te enviará un correo a la cuenta especificada con las instrucciones para recuperar la contraseña.';
    dashboardMessage = 'El poder que tienes en tus manos, es para ayudar a lograr los sueños de SlickSticky y los de nuestros clientes. Úsalo inspirado en la honestidad y en los principios fundamentales de un SER HUMANO.';
}

