import { Observable } from 'rxjs';
import {
  ListCampaignMonthResponseDTO,
  ListCampaignsStateMonthResponseDTO,
  ListFilterCampaniaEstadoMesUserRequestDTO,
  ListFilterCampaniaMesUserRequestDTO,
  ListInvestmentMonthUserResponseDTO,
  ListStickerMediaMonthUserResponseDTO
} from 'src/app/data/dto/ReportsDTO';

export abstract class ReportsUserRepository {
  abstract campaignMonthUserReport(request: ListFilterCampaniaMesUserRequestDTO
  ): Observable<ListCampaignMonthResponseDTO>;
  abstract investmentMonthUserReport(request: ListFilterCampaniaMesUserRequestDTO
  ): Observable<ListInvestmentMonthUserResponseDTO>;
  abstract campaignsStateMonthUserReport(request: ListFilterCampaniaEstadoMesUserRequestDTO
  ): Observable<ListCampaignsStateMonthResponseDTO>;
  abstract stickerMediaMonthUserReport(request: ListFilterCampaniaMesUserRequestDTO
    ): Observable<ListStickerMediaMonthUserResponseDTO>;
}
