import { Observable } from 'rxjs';
import {
  DeleteUserRequestDTO,
  DeleteUserResponseDTO,
  FetchUserRequestDTO,
  FetchUserResponseDTO,
  ListUsersDTO,
  UpdateUserRequestDTO,
  UpdateUserResponseDTO,
} from 'src/app/data/dto/UserDTO';

export abstract class UsersRepository {
  abstract listUsers(): Observable<ListUsersDTO>;
  abstract deleteUser(
    request: DeleteUserRequestDTO
  ): Observable<DeleteUserResponseDTO>;
  abstract fetchUser(
    request: FetchUserRequestDTO
  ): Observable<FetchUserResponseDTO>;
  abstract updateUser(
    request: UpdateUserRequestDTO
  ): Observable<UpdateUserResponseDTO>;
}
