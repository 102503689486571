import { Localization } from 'src/app/shared/Localization';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';


declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const routes: RouteInfo[] = [
  {
    path: '/home',
    title: 'Inicio',
    icon: 'ri-dashboard-fill text-color icon25',
    class: '',
  },
  {
    path: '/new-register',
    title: 'Mi cuenta',
    icon: 'ri-survey-fill text-blue icon25',
    class: '',
  },
  {
    path: '/added-campaigns',
    title: 'Mis campañas',
    icon: 'ri-flag-2-fill text-purple icon25',
    class: '',
  },
  {
    path: '/inventory-campaign',
    title: 'Orquestar mis Campañas',
    icon: 'ri-checkbox-circle-fill text-green icon25',
    class: '',
  },
  {
    path: '/pay-or-invoice',
    title: 'Abonate o factura',
    icon: 'ri-money-dollar-box-fill text-blue icon25',
    class: '',
  },
  {
    path: '/my-notes-suggestions',
    title: 'Mis notas y sugerencias',
    icon: 'ri-booklet-fill text-yellow icon25',
    class: '',
  },
  {
    path: '/report-users',
    title: 'Reportes de usuario',
    icon: 'ri-line-chart-fill text-purple icon25',
    class: '',
  },
];

export const routesDigitalSocio: RouteInfo[] = [
  {
    path: '/home',
    title: 'Inicio',
    icon: 'ri-dashboard-fill text-color icon25',
    class: '',
  },
  {
    path: '/new-register',
    title: 'Mi cuenta',
    icon: 'ri-survey-fill text-blue icon25',
    class: '',
  },
  {
    path: '/list-campaign',
    title: 'Mi inventario',
    icon: 'ri-flag-2-fill text-purple icon25',
    class: '',
  },
  {
    path: '/dashboard-commission',
    title: 'Mis logros',
    icon: 'ri-pie-chart-2-fill text-yellow icon25',
    class: '',
  },
  {
    path: '/reports-digital-socio',
    title: 'Reportes digital socio',
    icon: 'ri-line-chart-fill text-purple icon25',
    class: '',
  },
  {
    path: '/dashboard-invoicing',
    title: 'Mis pagos',
    icon: 'ri-money-dollar-box-fill text-blue icon25',
    class: '',
  },
  {
    path: '/spaces-available',
    title: 'Espacios disponibles',
    icon: 'ri-reserved-line text-yellow icon25',
    class: '',
  },
  {
    path: '/my-notes-suggestions-digital',
    title: 'Mis notas y sugerencias',
    icon: 'ri-booklet-fill text-yellow icon25',
    class: '',
  }
];

export const routesAdmin: RouteInfo[] = [
  {
    path: '/home',
    title: 'Inicio',
    icon: 'ri-dashboard-fill text-color icon25',
    class: '',
  },
  {
    path: '/new-register',
    title: 'Mi cuenta',
    icon: 'ri-survey-fill text-blue icon25',
    class: '',
  },
  {
    path: '/added-campaigns-admin',
    title: 'Mis campañas',
    icon: 'ri-flag-2-fill text-purple icon25',
    class: '',
  },
  {
    path: '/inventory-campaign-admin',
    title: 'Orquestar mis Campañas',
    icon: 'ri-checkbox-circle-fill text-green icon25',
    class: '',
  },
  {
    path: '/pay-or-invoice-admin',
    title: 'Abonate o factura',
    icon: 'ri-money-dollar-box-fill text-blue icon25',
    class: '',
  },
  {
    path: '/my-notes-suggestions',
    title: 'Mis notas y sugerencias',
    icon: 'ri-booklet-fill text-yellow icon25',
    class: '',
  }
];

export const routesAdminDigital: RouteInfo[] = [
  {
    path: '/inventory-admin',
    title: 'Mi inventario',
    icon: 'ri-flag-2-fill text-purple icon25',
    class: '',
  },
  {
    path: '/dashboard-commission-admin',
    title: 'Mis logros',
    icon: 'ri-pie-chart-2-fill text-yellow icon25',
    class: '',
  },
  {
    path: '/dashboard-invoicing-admin',
    title: 'Mis pagos',
    icon: 'ri-money-dollar-box-fill text-blue icon25',
    class: '',
  },
  {
    path: '/spaces-available-admin',
    title: 'Espacios disponibles',
    icon: 'ri-reserved-line text-yellow icon25',
    class: '',
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public menuAdminDigital: any[];
  public isCollapsed = true;
  idProfile: number;
  nameProfile = '';

  constructor(
    public localization: Localization,
    private router: Router,
    private authenticationService: AuthService
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser
    .subscribe(currentUser => this.idProfile = currentUser != null ? currentUser.user.Profile.id : 0);

    if (this.idProfile === 3){
      this.menuItems = routesAdmin.filter((menuItem) => menuItem);
      this.menuAdminDigital = routesAdminDigital.filter((menuItem) => menuItem);
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
      });
    }

    if (this.idProfile === 1){
      this.menuItems = routesDigitalSocio.filter((menuItem) => menuItem);
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
      });
    }

    if (this.idProfile === 2){
      this.menuItems = routes.filter((menuItem) => menuItem);
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
      });
    }

  }

  closeLogout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    localStorage.clear();
  }

  onReportsAdmin(): void {
    this.router.navigate(['/report-admin']);
  }

  onReportsUser(): void {
    this.router.navigate(['/report-users']);
  }

  onReportsDigital(): void {
    this.router.navigate(['/reports-digital-socio']);
  }

  onUsers(): void {
    this.router.navigate(['/users']);
  }
}
