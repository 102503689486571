import { Observable } from 'rxjs';
import {
  CatalogCountryDTO,
  CatalogLocationDTO,
  CatalogMediaInventoryDTO,
  CatalogMunicipalityRequestDTO,
  CatalogMunicipalityResponseDTO,
  CatalogStateRequestDTO,
  CatalogStateResponseDTO,
  CatalogTypeDocumentDTO,
} from 'src/app/data/dto/CatalogDTO';

export abstract class CatalogRepository {
  abstract listTypeDocument(): Observable<CatalogTypeDocumentDTO>;
  abstract listCountry(): Observable<CatalogCountryDTO>;
  abstract listStates(request: CatalogStateRequestDTO): Observable<CatalogStateResponseDTO>;
  abstract listMediaInventory(): Observable<CatalogMediaInventoryDTO>;
  abstract listMunicipality(request: CatalogMunicipalityRequestDTO): Observable<CatalogMunicipalityResponseDTO>;
  abstract listLocation(): Observable<CatalogLocationDTO>;
}
