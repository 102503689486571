import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthGuard } from '../auth/interceptor';
import { UserGuard } from '../auth/interceptor/user.guard';
import { DigitalSocioGuard } from '../auth/interceptor/digitalSocio.guard';
import { AdminGuard } from '../auth/interceptor/admin.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./../pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
          canActivate: [AuthGuard],
      },
      {
        path: 'new-register',
        loadChildren: () =>
          import('./../pages/register/new-register/new-register.module').then(
            (m) => m.NewRegisterModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'register-person',
        loadChildren: () =>
          import('./../pages/register/form-person/form-person.module').then(
            (m) => m.FormPersonModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'register-person-moral',
        loadChildren: () =>
          import(
            './../pages/register/form-person-moral/form-person-moral.module'
          ).then((m) => m.FormPersonMoralModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory-campaign',
        loadChildren: () =>
          import(
            './../pages/inventory-campaign/inventory-campaign.module'
          ).then((m) => m.InventoryCampaignModule),
        canActivate: [UserGuard],
      },
      {
        path: 'inventory-campaign-admin',
        loadChildren: () =>
          import(
            './../admin/inventory-campaign-admin/inventory-campaign-admin.module'
          ).then((m) => m.InventoryCampaignAdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'list-scheduled-screens-admin',
        loadChildren: () =>
          import(
            './../admin/list-scheduled-screens-admin/list-scheduled-screens-admin.module'
          ).then((m) => m.ListScheduledScreensAdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'added-campaigns',
        loadChildren: () =>
          import(
            './../pages/list-added-campaigns/list-added-campaigns.module'
          ).then((m) => m.ListAddedCampaignsModule),
        canActivate: [UserGuard],
      },
      {
        path: 'pay-stripe',
        loadChildren: () =>
          import(
            './../pages/pay-stripe/pay-stripe.module'
          ).then((m) => m.PayStripeModule),
        canActivate: [UserGuard],
      },
      {
        path: 'dashboard-campaign',
        loadChildren: () =>
          import(
            './../pages/dashboard-campaign/dashboard-campaign.module'
          ).then((m) => m.DashboardCampaignModule),
        canActivate: [UserGuard],
      },
      {
        path: 'my-notes-suggestions',
        loadChildren: () =>
          import(
            './../pages/my-notes-suggestions/my-notes-suggestions.module'
          ).then((m) => m.MyNotesSuggestionsModule),
        canActivate: [UserGuard],
      },
      {
        path: 'my-notes-suggestions-digital',
        loadChildren: () =>
          import(
            './../digital-socio/my-notes-suggestions-digital/my-notes-suggestions-digital.module'
          ).then((m) => m.MyNotesSuggestionsDigitalModule),
        canActivate: [DigitalSocioGuard],
      },
      {
        path: 'my-campaigns',
        loadChildren: () =>
          import('./../pages/my-campaigns/my-campaigns.module').then(
            (m) => m.MyCampaignsModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'my-reports',
        loadChildren: () =>
          import('./../pages/my-reports/my-reports.module').then(
            (m) => m.MyReportsModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'pay-or-invoice',
        loadChildren: () =>
          import('./../pages/pay-or-invoice/pay-or-invoice.module').then(
            (m) => m.PayOrInvoiceModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'pay-or-invoice-admin',
        loadChildren: () =>
          import('./../admin/pay-or-invoice-admin/pay-or-invoice-admin.module').then(
            (m) => m.PayOrInvoiceAdminModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'list-invoices',
        loadChildren: () =>
          import('./../pages/list-invoices/list-invoices.module').then(
            (m) => m.ListInvoicesModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'billing-screen',
        loadChildren: () =>
          import(
            './../pages/billing-screen/billing-screen.module'
          ).then((m) => m.BillingScreenModule),
        canActivate: [UserGuard],
      },
      {
        path: 'billing-screen-admin',
        loadChildren: () =>
          import(
            './../admin/billing-screen-admin/billing-screen-admin.module'
          ).then((m) => m.BillingScreenAdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'list-invoices-admin',
        loadChildren: () =>
          import('./../admin/list-invoices-admin/list-invoices-admin.module').then(
            (m) => m.ListInvoicesAdminModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'dashboard-commission',
        loadChildren: () =>
          import(
            './../digital-socio/dashboard-commission/dashboard-commission.module'
          ).then((m) => m.DashboardCommissionModule),
        canActivate: [DigitalSocioGuard],
      },
      {
        path: 'list-scheduled-screens',
        loadChildren: () =>
          import(
            './../pages/list-scheduled-screens/list-scheduled-screens.module'
          ).then((m) => m.ListScheduledScreenModule),
        canActivate: [UserGuard],
      },
      {
        path: 'dashboard-commission-admin',
        loadChildren: () =>
          import(
            './../admin/dashboard-commission-admin/dashboard-commission-admin.module'
          ).then((m) => m.DashboardCommissionModule),
        canActivate: [DigitalSocioGuard],
      },
      {
        path: 'dashboard-invoicing',
        loadChildren: () =>
          import(
            './../digital-socio/dashboard-invoicing/dashboard-invoicing.module'
          ).then((m) => m.DashboardInvoicingModule),
        canActivate: [DigitalSocioGuard],
      },
      {
        path: 'upload-invoicing',
        loadChildren: () =>
          import(
            './../digital-socio/upload-invoicing/upload-invoicing.module'
          ).then((m) => m.UploadInvoicingModule),
        canActivate: [DigitalSocioGuard],
      },
      {
        path: 'campaign-digital-socio',
        loadChildren: () =>
          import(
            './../digital-socio/campaign-digital-socio/campaign-digital-socio.module'
          ).then((m) => m.CampaignDigitalSocioModule),
        canActivate: [DigitalSocioGuard],
      },
      {
        path: 'spaces-available',
        loadChildren: () =>
          import(
            './../digital-socio/spaces-available/spaces-available.module'
          ).then((m) => m.SpacesAvailableModule),
        canActivate: [DigitalSocioGuard],
      },
      {
        path: 'list-campaign',
        loadChildren: () =>
          import(
            './../digital-socio/list-campaigns/list-campaigns.module'
          ).then((m) => m.ListCampaignsModule),
        canActivate: [DigitalSocioGuard],
      },
      {
        path: 'my-campaigns-admin',
        loadChildren: () =>
          import(
            './../admin/my-campaigns-admin/my-campaigns-admin.module'
          ).then((m) => m.MyCampaignsAdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'added-campaigns-admin',
        loadChildren: () =>
          import(
            './../admin/list-campaigns-admin/list-campaigns-admin.module'
          ).then((m) => m.ListCampaignsAdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'search-invoices',
        loadChildren: () =>
          import('./../admin/search-invoices/search-invoices.module').then(
            (m) => m.SearchInvoicesModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'list-cases',
        loadChildren: () =>
          import('./../admin/list-cases/list-cases.module').then(
            (m) => m.ListCasesModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'dashboard-invoicing-admin',
        loadChildren: () =>
          import(
            './../admin/dashboard-invoicing-admin/dashboard-invoicing-admin.module'
          ).then((m) => m.DashboardInvoicingAdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'spaces-available-admin',
        loadChildren: () =>
          import(
            './../admin/spaces-available-admin/spaces-available-admin.module'
          ).then((m) => m.SpacesAvailableAdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'report-admin',
        loadChildren: () =>
          import('./../admin/admin-reports/admin-reports.module').then(
            (m) => m.AdminReportsModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'monthly-income-screens',
        loadChildren: () =>
          import(
            './../admin/monthly-income-screens/monthly-income-screens.module'
          ).then((m) => m.MonthlyIncomeScreensModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'campaigns-for-screens',
        loadChildren: () =>
          import(
            './../admin/campaigns-for-screens/campaigns-for-screens.module'
          ).then((m) => m.CampaignsForScreensModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'campaigns-for-screens-bar',
        loadChildren: () =>
          import(
            './../admin/campaigns-screens-barchart/campaigns-screens-barchart.module'
          ).then((m) => m.CampaignsScreensBarchartModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'stickers-monthly-campaign',
        loadChildren: () =>
          import(
            './../admin/stickers-monthly-campaign/stickers-monthly-campaign.module'
          ).then((m) => m.StickersMonthlyCampaignModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'report-users',
        loadChildren: () =>
          import(
            './../admin/report-users/reports-user-menu/reports-user-menu.module'
          ).then((m) => m.ReportsUserMenuModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'report-campaign-month',
        loadChildren: () =>
          import(
            './../admin/report-users/report-campaign-month/report-campaign-month.module'
          ).then((m) => m.ReportCampaignMonthModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'report-investment-month',
        loadChildren: () =>
          import(
            './../admin/report-users/report-investment-month/report-investment-month.module'
          ).then((m) => m.ReportInvestmentMonthModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'report-sticker-media',
        loadChildren: () =>
          import(
            './../admin/report-users/report-sticker-media/report-sticker-media.module'
          ).then((m) => m.ReportStickerMediaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'report-campaigns-state-month',
        loadChildren: () =>
          import(
            './../admin/report-users/report-campaigns-state-month/report-campaigns-state-month.module'
          ).then((m) => m.ReportCampaignsStateMonthModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'report-campaign-screen-historical',
        loadChildren: () =>
          import(
            './../admin/report-users/report-campaign-screen-historical/report-campaign-screen-historical.module'
          ).then((m) => m.ReportCampaignScreenHistoricalModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'historical-sticker-media',
        loadChildren: () =>
          import(
            './../admin/report-users/historical-sticker-media/historical-sticker-media.module'
          ).then((m) => m.HistoricalStickerMediaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'historical-campaigns-state-screens',
        loadChildren: () =>
          import(
            './../admin/report-users/history-campaigns-state-screens/history-campaigns-state-screens.module'
          ).then((m) => m.HistoryCampaignsStateScreensModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-digital-socio',
        loadChildren: () =>
          import(
            './../report-digital-socio/reports-socio-menu/reports-socio-menu.module'
          ).then((m) => m.ReportsSocioMenuModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-campaigns-month-screen',
        loadChildren: () =>
          import(
            './../report-digital-socio/campaigns-month-screen/campaigns-month-screen.module'
          ).then((m) => m.CampaignsMonthScreenModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-income-screen-month',
        loadChildren: () =>
          import(
            './../report-digital-socio/income-screen-month/income-screen-month.module'
          ).then((m) => m.IncomeScreenMonthModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-stickers-month-screen',
        loadChildren: () =>
          import(
            './../report-digital-socio/stickers-month-screen/stickers-month-screen.module'
          ).then((m) => m.StickersMonthScreenModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-state-month',
        loadChildren: () =>
          import(
            './../report-digital-socio/state-month/state-month.module'
          ).then((m) => m.StateMonthModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'campaigns-screen-historical',
        loadChildren: () =>
          import(
            './../report-digital-socio/campaigns-screen-historical/campaigns-screen-historical.module'
          ).then((m) => m.CampaignsScreenHistoricalModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'income-general-historical',
        loadChildren: () =>
          import(
            './../report-digital-socio/income-general-historical/income-general-historical.module'
          ).then((m) => m.IncomeGeneralHistoricalModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'stickers-screen-historical',
        loadChildren: () =>
          import(
            './../report-digital-socio/stickers-screen-historical/stickers-screen-historical.module'
          ).then((m) => m.StickersScreenHistoricalModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'participation-state-historical',
        loadChildren: () =>
          import(
            './../report-digital-socio/participation-state-historical/participation-state-historical.module'
          ).then((m) => m.ParticipationStateHistoricalModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./../admin/users/list-users/list-users.module').then(
            (m) => m.ListUsersModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'new-user',
        loadChildren: () =>
          import('./../admin/users/new-user/new-user.module').then(
            (m) => m.NewUserModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'update-user/:id',
        loadChildren: () =>
          import('./../admin/users/update-user/update-user.module').then(
            (m) => m.UpdateUserModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'inventory-admin',
        loadChildren: () =>
          import(
            './../admin/list-inventory-admin/list-inventory-admin.module'
          ).then((m) => m.ListInventoryAdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'add-spaces-available',
        loadChildren: () =>
          import('./../admin/add-spaces-available/add-spaces.module').then(
            (m) => m.AddSpacesAvailableModule
          ),
        canActivate: [AdminGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
