import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';
import { CatalogRepository } from 'src/app/core/repositories/catalog/CatalogRepository';
import { environment } from 'src/environments/environment';
import {
  CatalogCountryDTO,
  CatalogLocationDTO,
  CatalogMediaInventoryDTO,
  CatalogMunicipalityRequestDTO,
  CatalogMunicipalityResponseDTO,
  CatalogStateRequestDTO,
  CatalogStateResponseDTO,
  CatalogTypeDocumentDTO,
} from '../../dto/CatalogDTO';

@Injectable({
  providedIn: 'root',
})
export class CatalogRestRepository extends CatalogRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listTypeDocument(): Observable<CatalogTypeDocumentDTO> {
    const endpoint = environment.catalog_type_documents_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<CatalogTypeDocumentDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listCountry(): Observable<CatalogCountryDTO> {
    const endpoint = environment.get_catalog_countries_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<CatalogCountryDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listStates(id: CatalogStateRequestDTO): Observable<CatalogStateResponseDTO> {
    const endpoint = environment.get_catalog_states_endpoint;
    const params = new HttpParams({
      fromObject: {
        idPais: id.idPais,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<CatalogStateResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listMediaInventory(): Observable<CatalogMediaInventoryDTO> {
    const endpoint = environment.get_catalog_media_inventory_enpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<CatalogMediaInventoryDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listMunicipality(
    id: CatalogMunicipalityRequestDTO
  ): Observable<CatalogMunicipalityResponseDTO> {
    const endpoint = environment.get_catalog_municipality_endpoint;
    const params = new HttpParams({
      fromObject: {
        idEstado: id.idEstado,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<CatalogMunicipalityResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listLocation(): Observable<CatalogLocationDTO> {
    const endpoint = environment.get_catalog_localidad_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<CatalogLocationDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
