import { Observable } from 'rxjs';
import {
  BillingRequestDTO,
  BillingResponseDTO
} from 'src/app/data/dto/InvoicesDTO';

export abstract class BillingRepository {
  abstract invoicesUser(
    request: BillingRequestDTO
  ): Observable<BillingResponseDTO>;
}
