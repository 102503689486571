import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ListUsersUseCase } from 'src/app/core/usecases/user/ListUsersUseCase';
import { UsersDTO } from 'src/app/data/dto/UserDTO';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  public error = '';
  public loader = true;
  listUsers: UsersDTO[] = [];
  nameProfile = '';
  nameUser = '';
  idUser: number;
  idProfile: number;
  idTypePerson: number;
  array = [];

  constructor(
    private router: Router,
    private authenticationService: AuthService,
    private readonly listUsersUseCase: ListUsersUseCase,
  ) {
  }

  ngOnInit(): void {
    this.authenticationService.currentUser
    .subscribe(currentUser => this.nameUser = currentUser != null ? currentUser.user.chrUserName : '');
    this.authenticationService.currentUser
    .subscribe(currentUser => this.idUser = currentUser != null ? currentUser.user.id : 0);
    this.authenticationService.currentUser
    .subscribe(currentUser => this.nameProfile = currentUser != null ? currentUser.user.Profile.chrNameProfile : '');
    this.authenticationService.currentUser
    .subscribe(currentUser => this.idProfile = currentUser != null ? currentUser.user.Profile.id : 0);
    this.onTypePerson();
  }

  onTypePerson(): void {
      this.listUsersUseCase.execute().subscribe(
        (response) => {
          this.listUsers = [];
          this.listUsers = this.listUsers.concat(response.response);
          this.listUsers.forEach((element) => {
            if (this.idUser === element.idUser) {
              this.idTypePerson = element.tipoPersona.idTipoPersona;
            }
          });
          this.loader = false;
        },
        (error) => {
          this.error = error;
          this.loader = false;
        }
      );
  }

  closeLogout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    localStorage.clear();
  }

  onMedium(): void {
    if ( this.idProfile === 1 ) {
      this.router.navigate(['/my-notes-suggestions-digital']);
    }
    if ( this.idProfile === 2 ) {
      this.router.navigate(['/my-notes-suggestions']);
    }
    if ( this.idProfile === 3 ) {
      this.router.navigate(['/list-cases']);
    }
  }

  onMyProfile(): void {
    if ( this.idTypePerson === 1 ) {
      this.router.navigate(['/register-person']);
    }
    if ( this.idTypePerson === 2 ) {
      this.router.navigate(['/register-person-moral']);
    }
    if ( this.idTypePerson === null ) {
      this.router.navigate(['/new-register']);
    }
  }

}
