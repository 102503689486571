import { Observable } from 'rxjs';
import { DownloadFileDTO } from 'src/app/data/dto/MailBoxNotesDTO';
import {
  DownloadPDFDTO,
  DownloadExcelDTO,
  DownloadExcelRequestDTO
} from 'src/app/data/dto/InventoryDTO';

import {
  DownloadInvoicesPDFDTO
} from 'src/app/data/dto/InvoicesDTO';
import {
  DownloadCampaignDTO
} from 'src/app/data/dto/CampaignDTO';

export abstract class DownloadFileRepository {
  abstract DownloadFileMailBox(
    request: DownloadFileDTO
    ): Observable<any>;
  abstract DownloadPDF(
    file: DownloadPDFDTO
    ): Observable<any>;
  abstract DownloadExcel(
    file: DownloadExcelRequestDTO
    ): Observable<DownloadExcelDTO>;
  abstract DownloadInvoicesPDF(
    file: DownloadInvoicesPDFDTO
    ): Observable<any>;
    abstract DownloadFileCampaing(
      request: DownloadCampaignDTO
      ): Observable<any>;
}


