<footer class="footer">
  <div class="row align-items-center justify-content-xl-between without-margin-right without-margin-left">
    <div class="col-xl-6">
      <div class="copyright text-center text-xl-left text-muted">
        &copy; {{ test | date: "yyyy" }} <a href="https://slicksticky.com.mx/" class="font-weight-bold ml-1" target="_blank">Slicksticky</a>
      </div>
    </div>
    <div class="col-xl-6">
      <ul class="nav nav-footer justify-content-center justify-content-xl-end">
        <li class="nav-item">
          <a href="#" class="nav-link" target="_blank">Todos los derechos reservados</a>
        </li>
      </ul>
    </div>
  </div>
</footer>