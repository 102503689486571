import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor } from './auth/interceptor';
import { UserRepository } from './core/repositories/auth/UserRepository';
import { UserRestRepository } from './data/repository/auth/UserRestRepository';
import { Localization, SpanishLocalization } from './shared/Localization';
import { CatalogRestRepository } from './data/repository/catalog/CatalogRestRepository';
import { CatalogRepository } from './core/repositories/catalog/CatalogRepository';
import { UploadTaxRestRepository } from './data/repository/upload-tax/UploadTaxRestRepository';
import { UploadTaxRepository } from './core/repositories/upload-tax/UploadTaxRepository';
import { InventoryRepository } from './core/repositories/inventory/InventoryRepository';
import { InventoryRestRepository } from './data/repository/inventory/InventoryRestRepository';
import { CampaignRepository } from './core/repositories/campaign/CampaignRepository';
import { CampaignRestRepository } from './data/repository/campaign/CampaignRestRepository';
import { UploadFileRepository } from './core/repositories/UploadFileRepository';
import { UploadFileRestRepository } from './data/repository/UploadFileRestRepository';
import { MailBoxRepository } from './core/repositories/mailbox/MailBoxRepository';
import { MailBoxRestRepository } from './data/repository/mailbox/MailBoxRestRepository';
import { InvoiceRepository } from './core/repositories/invoice/InvoiceRepository';
import { InvoiceRestRepository } from './data/repository/invoice/InvoiceRestRepository';
import { UsersRepository } from './core/repositories/user/UsersRepository';
import { UsersRestRepository } from './data/repository/user/UsersRestRepository';
import { DownloadFileRepository } from './core/repositories/DownloadFileRepository';
import { DownloadFileRestRepository } from './data/repository/DownloadFileRestRepository';
import { ReportsAdminRepository } from './core/repositories/reports/ReportsAdminRepository';
import { ReportsAdminRestRepository } from './data/repository/reports/ReportsAdminRestRepository';
import { ReportsUserRestRepository } from './data/repository/reports/ReportsUserRestRepository';
import { ReportsUserRepository } from './core/repositories/reports/ReportsUserRepository';
import { BillingRepository } from './core/repositories/invoice/BillingRepository';
import { BillingRestRepository } from './data/repository/invoice/BillingRestRepository';


@NgModule({
  declarations: [AppComponent, HomeComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    NgbModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule
  ],
  providers: [
    { provide: Localization, useClass: SpanishLocalization },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: UserRepository, useClass: UserRestRepository },
    { provide: CatalogRepository, useClass: CatalogRestRepository },
    { provide: UploadTaxRepository, useClass: UploadTaxRestRepository },
    { provide: InventoryRepository, useClass: InventoryRestRepository },
    { provide: CampaignRepository, useClass: CampaignRestRepository },
    { provide: UploadFileRepository, useClass: UploadFileRestRepository },
    { provide: MailBoxRepository, useClass: MailBoxRestRepository },
    { provide: InvoiceRepository, useClass: InvoiceRestRepository },
    { provide: UsersRepository, useClass: UsersRestRepository },
    { provide: DownloadFileRepository, useClass: DownloadFileRestRepository },
    { provide: ReportsAdminRepository, useClass: ReportsAdminRestRepository },
    { provide: ReportsUserRepository, useClass: ReportsUserRestRepository },
    { provide: BillingRepository, useClass: BillingRestRepository },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
