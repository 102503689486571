import {
  CampaignsMonthScreenReportDigitalSocioDTO,
  ListCampaignMonthUserRequestDTO,
  ListCampaignMonthUserResponseDTO,
  ListCampaignScreenHistoricalUserRequestDTO,
  ListCampaignScreenHistoricalUserResponseDTO,
  ListCampaignsForScreensBarDTO,
  ListCampaignsScreenHistoricalDigitalSocioRequestDTO,
  ListCampaignsScreenHistoricalDigitalSocioResponseDTO,
  ListCampaignsStateScreensUserRequestDTO,
  ListCampaignStateScreenlUserResponseDTO,
  ListCampaniaDTO,
  ListDigitalSocioDTO,
  ListEstadosDTO,
  ListHistoricalStickerMediaUserRequestDTO,
  ListHistoricalStickerMediaUserResponseDTO,
  ListMonthlyIncomeReportByScreensAdminDTO,
  ListScreenCampaignsDTO,
  ListScreenDTO,
  ListStickersMonthlyCampaigntReportAdminDTO,
} from './../../dto/ReportsDTO';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ReportsAdminRepository } from 'src/app/core/repositories/reports/ReportsAdminRepository';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ReportsAdminRestRepository extends ReportsAdminRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }
  listScreen(): Observable<ListScreenDTO> {
    const endpoint = environment.report_list_pantallas;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListScreenDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listDigitalSocio(): Observable<ListDigitalSocioDTO> {
    const endpoint = environment.report_list_digital_socio;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListDigitalSocioDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listCampaign(): Observable<ListCampaniaDTO> {
    const endpoint = environment.report_list_campanias;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListCampaniaDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listState(): Observable<ListEstadosDTO> {
    const endpoint = environment.report_list_estados;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListEstadosDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  monthlyIncomeReport(): Observable<ListMonthlyIncomeReportByScreensAdminDTO> {
    const endpoint = environment.report_admin_income_monthly;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListMonthlyIncomeReportByScreensAdminDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  screenCampaingReport(): Observable<ListScreenCampaignsDTO> {
    const endpoint = environment.report_admin_income_monthly;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListScreenCampaignsDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  stickersMonthlyCampaignReport(): Observable<ListStickersMonthlyCampaigntReportAdminDTO> {
    const endpoint = environment.report_admin_stickers_monthly_campaign;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListStickersMonthlyCampaigntReportAdminDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  campaignsMonthScreenReport(): Observable<CampaignsMonthScreenReportDigitalSocioDTO> {
    const endpoint = environment.report_digital_socio_campaigns_month_screen;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<CampaignsMonthScreenReportDigitalSocioDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  campaignsScreenHistoricalDigitalSocioReport(
    request: ListCampaignsScreenHistoricalDigitalSocioRequestDTO
  ): Observable<ListCampaignsScreenHistoricalDigitalSocioResponseDTO> {
    const endpoint = environment.report_digital_socio_campaigns_screen_historical;
    const params = new HttpParams({
      fromObject: {
        digital: request.digital,
        pantalla: request.pantalla,
        mes: request.mes
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListCampaignsScreenHistoricalDigitalSocioResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  campaignScreenHistoricalUserReport(
    request: ListCampaignScreenHistoricalUserRequestDTO
  ): Observable<ListCampaignScreenHistoricalUserResponseDTO> {
    const endpoint = environment.report_user_filtro_campania_mes;
    const params = new HttpParams({
      fromObject: {
        camp: request.camp,
        mes: request.mes,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListCampaignScreenHistoricalUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  historicalStickerMediaUserReport(
    request: ListHistoricalStickerMediaUserRequestDTO
  ): Observable<ListHistoricalStickerMediaUserResponseDTO> {
    const endpoint = environment.report_user_filtro_campania_mes;
    const params = new HttpParams({
      fromObject: {
        camp: request.camp,
        mes: request.mes,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListHistoricalStickerMediaUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  campaignsStateScreensUserReport(
    request: ListCampaignsStateScreensUserRequestDTO
  ): Observable<ListCampaignStateScreenlUserResponseDTO> {
    const endpoint = environment.report_user_campaign_screen_historical;
    const params = new HttpParams({
      fromObject: {
        camp: request.camp,
        mes: request.mes,
        pantalla: request.pantalla,
        estado: request.estado,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListCampaignStateScreenlUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  campaignMonthUserReport(
    request: ListCampaignMonthUserRequestDTO
  ): Observable<ListCampaignMonthUserResponseDTO> {
    const endpoint = environment.report_user_filtro_campania_mes;
    const params = new HttpParams({
      fromObject: {
        camp: request.camp,
        mes: request.mes,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListCampaignMonthUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  campaignsForScreensBarReport(): Observable<ListCampaignsForScreensBarDTO> {
    const endpoint = environment.report_admin_campaigns_for_screens_bar;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListCampaignsForScreensBarDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
