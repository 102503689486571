import { Observable } from 'rxjs';
import {
  CampaignRequestDTO,
  CampaignResponseDTO,
  CampaignByUserRequestDTO,
  CampaignByUserResponseDTO,
  CampaignByAdminRequestDTO,
  CampaignByAdminResponseDTO,
  FetchCampaignRequestDTO,
  FetchCampaignResponseDTO,
  CampaignApproveRequestDTO,
  CampaignApproveResponseDTO,
  CampaignCloseRequestDTO,
  CampaignCloseResponseDTO,
  HireScreenRequestDTO,
  HireScreenResponseDTO
} from 'src/app/data/dto/CampaignDTO';

export abstract class CampaignRepository {
  abstract listCampaign(idStatus: CampaignRequestDTO): Observable<CampaignResponseDTO>;
  abstract listByUser(idUser: CampaignByUserRequestDTO): Observable<CampaignByUserResponseDTO>;
  abstract listByAdmin(idUser: CampaignByAdminRequestDTO): Observable<CampaignByAdminResponseDTO>;
  abstract fetchCampaign(request: FetchCampaignRequestDTO): Observable<FetchCampaignResponseDTO>;
  abstract approveCampaign(request: CampaignApproveRequestDTO): Observable<CampaignApproveResponseDTO>;
  abstract closeCampaign(request: CampaignCloseRequestDTO): Observable<CampaignCloseResponseDTO>;
  abstract hireScreen(request: HireScreenRequestDTO): Observable<HireScreenResponseDTO>;
}
