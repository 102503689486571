<nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
    <div class="container-fluid">
      <!-- Brand -->
      <a class="h6 mb-0 text-uppercase d-none d-lg-inline-block link-gray">{{nameProfile}}</a>
      <!-- User -->
      <ul class="navbar-nav align-items-center d-none d-md-flex">
        <li class="nav-item" ngbDropdown placement="bottom-right">
          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="slicksticky" src="assets/img/logo-slick.png">
              </span>
              <div class="media-body ml-3 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold text-dark">{{nameUser}} - 00024{{idUser}}</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">¡Bienvenido!</h6>
            </div>
            <a routerLinkActive="active" (click)="onMyProfile()" class="dropdown-item">
              <i class="ri-user-3-fill"></i>
              <span>Mi perfil</span>
            </a>
            <a routerLinkActive="active" (click)="onMedium()" class="dropdown-item">
              <i class="ri-settings-5-fill"></i>
              <span>Soporte</span>
            </a>
            <div class="dropdown-divider"></div>
            <a [routerLink]="" (click)="closeLogout()" class="dropdown-item">
              <i class="ri-door-closed-fill"></i>
              <span>Cerrar sesión</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>

