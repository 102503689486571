import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ReportsUserRepository } from 'src/app/core/repositories/reports/ReportsUserRepository';
import {
  ListCampaignMonthResponseDTO,
  ListCampaignsStateMonthResponseDTO,
  ListCampaniaDTO,
  ListFilterCampaniaEstadoMesUserRequestDTO,
  ListFilterCampaniaMesUserRequestDTO,
  ListInvestmentMonthUserResponseDTO,
  ListStickerMediaMonthUserResponseDTO
} from '../../dto/ReportsDTO';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportsUserRestRepository extends ReportsUserRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listCampaign(): Observable<ListCampaniaDTO> {
    const endpoint = environment.report_list_campanias;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListCampaniaDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }


  campaignMonthUserReport(
    request: ListFilterCampaniaMesUserRequestDTO
  ): Observable<ListCampaignMonthResponseDTO> {
    const endpoint = environment.report_user_campania_por_mes;
    const params = new HttpParams({
      fromObject: {
        camp: request.camp,
        mes: request.mes,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListCampaignMonthResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  investmentMonthUserReport(
    request: ListFilterCampaniaMesUserRequestDTO
  ): Observable<ListInvestmentMonthUserResponseDTO> {
    const endpoint = environment.report_user_investment_month;
    const params = new HttpParams({
      fromObject: {
        camp: request.camp,
        mes: request.mes,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListInvestmentMonthUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  campaignsStateMonthUserReport(
    request: ListFilterCampaniaEstadoMesUserRequestDTO
  ): Observable<ListCampaignsStateMonthResponseDTO> {
    const endpoint = environment.report_user_campaigns_state_month;
    const params = new HttpParams({
      fromObject: {
        camp: request.camp,
        mes: request.mes,
        estado: request.estado,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListCampaignsStateMonthResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  stickerMediaMonthUserReport(
    request: ListFilterCampaniaMesUserRequestDTO
  ): Observable<ListStickerMediaMonthUserResponseDTO> {
    const endpoint = environment.report_user_sticker_media_month;
    const params = new HttpParams({
      fromObject: {
        camp: request.camp,
        mes: request.mes,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListStickerMediaMonthUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }


}
