import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DownloadFileRepository } from 'src/app/core/repositories/DownloadFileRepository';
import { DownloadFileDTO } from '../dto/MailBoxNotesDTO';
import {
  DownloadPDFDTO,
  DownloadExcelRequestDTO,
  DownloadExcelDTO
} from '../dto/InventoryDTO';
import {
  DownloadInvoicesPDFDTO
} from '../dto/InvoicesDTO';
import {
  DownloadCampaignDTO
} from 'src/app/data/dto/CampaignDTO';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileRestRepository extends DownloadFileRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  DownloadFileMailBox(file: DownloadFileDTO): Observable<any> {
    const endpoint = environment.download_files_notesmailbox_endpoint;
    const id = file.idFile;
    const httpOptions = {
      headers: this.headers,
      responseType: 'blob' as 'json',
    };
    return this.http
      .post<any>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(file),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  DownloadPDF(
    file: DownloadPDFDTO): Observable<any> {
    const endpoint = environment.download_file_pdf_endpoint;
    const httpOptions = {
      headers: this.headers,
      responseType: 'blob' as 'json',
    };
    return this.http
      .post<any>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(file),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  DownloadExcel(file: DownloadExcelRequestDTO): Observable<DownloadExcelDTO> {
    const endpoint = environment.download_file_excel_endpoint;
    const httpOptions = {
      headers: this.headers
    };
    return this.http
      .post<DownloadExcelDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(file),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  DownloadInvoicesPDF(
    file: DownloadInvoicesPDFDTO): Observable<any> {
    const endpoint = environment.download_file_invoices_pdf_endpoint;
    const httpOptions = {
      headers: this.headers,
      responseType: 'blob' as 'json',
    };
    return this.http
      .post<any>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(file),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  DownloadFileCampaing(file: DownloadCampaignDTO): Observable<any> {
    const endpoint = environment.download_files_campaing_endpoint;
    const id = file.idFile;
    const httpOptions = {
      headers: this.headers,
      responseType: 'blob' as 'json',
    };
    return this.http
      .post<any>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(file),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
