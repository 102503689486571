import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListUsersDTO } from 'src/app/data/dto/UserDTO';
import { UsersRepository } from '../../repositories/user/UsersRepository';

@Injectable({
    providedIn: 'root'
})

export class ListUsersUseCase implements UseCase<string, ListUsersDTO> {
    constructor(
        private usersRepository: UsersRepository,
    ) { }

    execute(): Observable<ListUsersDTO> {
        return this.usersRepository.listUsers();
    }
}
