<nav
  class="navbar navbar-vertical navbar-expand-md navbar-light bg-white"
  id="sidenav-main"
>
  <div class="container-fluid">
    <!-- Toggler -->
    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
      aria-controls="sidenav-collapse-main"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <a class="navbar-brand pt-0">
      <img
        src="/assets/img/logos/logo-black.png"
        class="navbar-brand-img"
        alt="logo-slicksticky"
      />
    </a>
    <!-- User -->
    <ul class="nav align-items-center d-md-none">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link nav-link-icon" role="button" ngbDropdownToggle>
          <i class="ni ni-bell-55"></i>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)">Action</a>
          <a class="dropdown-item" href="javascript:void(0)">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)"
            >Something else here</a
          >
        </div>
      </li>
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="assets/img/logo-slick.png" />
            </span>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">{{localization.welcome}}</h6>
          </div>
          <a routerLinkActive="active" class="dropdown-item">
            <i class="ri-user-3-fill"></i>
            <span>{{localization.myProfile}}</span>
          </a>
          <a routerLinkActive="active" class="dropdown-item">
            <i class="ri-settings-5-fill"></i>
            <span>{{localization.support}}</span>
          </a>
          <div class="dropdown-divider"></div>
          <a [routerLink]="" (click)="closeLogout()" class="dropdown-item">
            <i class="ri-door-closed-fill"></i>
            <span>{{localization.signOff}}</span>
          </a>
        </div>
      </li>
    </ul>
    <!-- Collapse -->
    <div
      class="collapse navbar-collapse"
      [ngbCollapse]="isCollapsed"
      id="sidenav-collapse-main"
    >
      <!-- Collapse header -->
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a routerLinkActive="active">
              <img src="/assets/img/logos/logo-black.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              (click)="isCollapsed = !isCollapsed"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="idProfile === 1 || idProfile === 2">
        <ul class="navbar-nav">
          <li
            *ngFor="let menuItem of menuItems"
            class="{{ menuItem.class }} nav-item"
          >
            <a
              routerLinkActive="active"
              [routerLink]="[menuItem.path]"
              class="nav-link"
            >
              <i class="{{ menuItem.icon }}"></i>
              {{ menuItem.title }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="" (click)="closeLogout()">
              <i class="ri-door-closed-fill text-color icon25"></i>
              {{localization.signOff}}
            </a>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="idProfile === 3">
        <h6 class="navbar-heading text-muted">{{localization.user}}</h6>
        <ul class="navbar-nav">
          <li
            *ngFor="let menuItem of menuItems"
            class="{{ menuItem.class }} nav-item"
          >
            <a
              routerLinkActive="active"
              [routerLink]="[menuItem.path]"
              class="nav-link"
            >
              <i class="{{ menuItem.icon }}"></i>
              {{ menuItem.title }}
            </a>
          </li>
        </ul>
        <hr class="my-3" />
        <h6 class="navbar-heading text-muted">{{localization.digitalPartner}}</h6>
        <ul class="navbar-nav">
          <li
            *ngFor="let menuItem of menuAdminDigital"
            class="{{ menuItem.class }} nav-item"
          >
            <a
              routerLinkActive="active"
              [routerLink]="[menuItem.path]"
              class="nav-link"
            >
              <i class="{{ menuItem.icon }}"></i>
              {{ menuItem.title }}
            </a>
          </li>
        </ul>
        <hr class="my-3" />
        <h6 class="navbar-heading text-muted"></h6>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="" (click)="onUsers()">
              <i class="ri-user-3-fill text-blue icon25"></i>
              {{localization.users}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#collapseReport">
              <i class="ri-line-chart-fill text-purple icon25"></i>
              {{localization.reports}}
            </a>
            <div id="collapseReport" class="collapse">
                <div class="bg-light py-2 collapse-inner rounded">
                    <a class="collapse-item" [routerLink]="" (click)="onReportsAdmin()">
                      <i class="fas fa-circle mr-2"></i> {{localization.reportsAdmin}}
                    </a>
                    <a class="collapse-item" [routerLink]="" (click)="onReportsDigital()">
                      <i class="fas fa-circle mr-2"></i> {{localization.reportsSocio}}
                    </a>
                    <a class="collapse-item" [routerLink]="" (click)="onReportsUser()">
                      <i class="fas fa-circle mr-2"></i> {{localization.reportUser}}
                    </a>
                </div>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="" (click)="closeLogout()">
              <i class="ri-door-closed-fill text-color icon25"></i>
              {{localization.signOff}}
            </a>
          </li>
        </ul>
      </ng-container>
      <!-- Navigation -->
    </div>
  </div>
</nav>
