import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UploadFileRepository } from 'src/app/core/repositories/UploadFileRepository';
import { CreateCampaignRequestDTO, CreateCampaignResponseDTO } from '../dto/CampaignDTO';

@Injectable({
  providedIn: 'root',
})
export class UploadFileRestRepository extends UploadFileRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    super();
  }

  createCampaign(
    file: CreateCampaignRequestDTO
  ): Observable<CreateCampaignResponseDTO> {
    const endpoint = environment.campaign_endpoint;
    const httpOptions = { headers: this.headers };
    const formData = new FormData();
    formData.append('nombre', file.nombre);
    formData.append('fileToUpload', file.fileToUpload);
    formData.append('duracion', file.duracion.toString());
    return this.http
      .post<CreateCampaignResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        formData,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
