import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { CampaignRepository } from 'src/app/core/repositories/campaign/CampaignRepository';
import {
  CampaignApproveRequestDTO,
  CampaignApproveResponseDTO,
  CampaignCloseRequestDTO,
  CampaignCloseResponseDTO,
  CampaignRequestDTO,
  FetchCampaignRequestDTO,
  FetchCampaignResponseDTO,
  HireScreenRequestDTO,
  HireScreenResponseDTO,
} from '../../dto/CampaignDTO';
import {
  CampaignResponseDTO,
  CampaignByUserRequestDTO,
  CampaignByUserResponseDTO,
  CampaignByAdminRequestDTO,
  CampaignByAdminResponseDTO,
} from 'src/app/data/dto/CampaignDTO';

@Injectable({
  providedIn: 'root',
})
export class CampaignRestRepository extends CampaignRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listCampaign(request: CampaignRequestDTO): Observable<CampaignResponseDTO> {
    const endpoint = environment.campaign_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<CampaignResponseDTO>(
        `${environment.url_base}/${endpoint}/${request.idStatus}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listByUser(
    user: CampaignByUserRequestDTO
  ): Observable<CampaignByUserResponseDTO> {
    const endpoint = environment.campaign_user_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<CampaignByUserResponseDTO>(
        `${environment.url_base}/${endpoint}/${user.idUser}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listByAdmin(
    user: CampaignByAdminRequestDTO
  ): Observable<CampaignByAdminResponseDTO> {
    const endpoint = environment.campaign_admin_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<CampaignByAdminResponseDTO>(
        `${environment.url_base}/${endpoint}/${user.idUser}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchCampaign(
    request: FetchCampaignRequestDTO
  ): Observable<FetchCampaignResponseDTO> {
    const endpoint = environment.campaign_screen_endpoint;
    const params = new HttpParams({
      fromObject: {
        medio: request.medioId,
        pais: request.paisId,
        estado: request.estadoId,
        municipio: request.municipioId,
        rangoPrecio: request.rangoPrecio,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<FetchCampaignResponseDTO>(
        `${environment.url_base}/${endpoint}/${request.idUser}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  approveCampaign(
    request: CampaignApproveRequestDTO
  ): Observable<CampaignApproveResponseDTO> {
    const endpoint = environment.campaign_endpoint;
    const id = request.idCampania;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<CampaignApproveResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  closeCampaign(
    request: CampaignCloseRequestDTO
  ): Observable<CampaignCloseResponseDTO> {
    const endpoint = environment.close_campaign_endpoint;
    const id = request.idCampania;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<CampaignCloseResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  hireScreen(
    request: HireScreenRequestDTO
  ): Observable<HireScreenResponseDTO> {
    const endpoint = environment.hire_screen_endpoint;
    const id = request.idScreen;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<HireScreenResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
