import { Observable } from 'rxjs';
import {
  LoginDTO,
  LoginResponseDTO,
  RecoveryRequestDTO,
  RecoveryResponseDTO,
  RegisterRequestDTO,
  RegisterResponseDTO,
} from '../../../data/dto/LoginDTO';

export abstract class UserRepository {
  abstract login(auth: LoginDTO): Observable<LoginResponseDTO>;
  abstract logout(): Observable<string>;
  abstract register(request: RegisterRequestDTO): Observable<RegisterResponseDTO>;
  abstract recover(request: RecoveryRequestDTO): Observable<RecoveryResponseDTO>;
}
