import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UploadTaxRepository } from 'src/app/core/repositories/upload-tax/UploadTaxRepository';
import { environment } from 'src/environments/environment';
import { CatalogTypeDocumentDTO } from '../../dto/CatalogDTO';
import { UploadTaxRequestDTO, UploadTaxResponseDTO } from '../../dto/UploadTaxDTO';
import { FetchDocumentsRequestDTO, FetchDocumentsResponseDTO } from 'src/app/data/dto/UploadTaxDTO';

@Injectable({
  providedIn: 'root',
})
export class UploadTaxRestRepository extends UploadTaxRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  uploadDocumentTax(request: UploadTaxRequestDTO): Observable<UploadTaxResponseDTO> {
    const endpoint = environment.upload_documents_tax_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<UploadTaxResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchDocuments(user: FetchDocumentsRequestDTO): Observable<FetchDocumentsResponseDTO> {
    const endpoint = environment.documents_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<FetchDocumentsResponseDTO>(
        `${environment.url_base}/${endpoint}/${user.idUser}/${user.idTypePerson}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
