import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { BillingRepository } from 'src/app/core/repositories/invoice/BillingRepository';
import {
  BillingResponseDTO,
  BillingRequestDTO
} from '../../dto/InvoicesDTO';
@Injectable({
  providedIn: 'root',
})
export class BillingRestRepository implements BillingRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

  }

  invoicesUser(
    request: BillingRequestDTO
  ): Observable<BillingResponseDTO> {
    const endpoint = environment.billing_endpoint;
    const httpOptions = { headers: this.headers, };
    return this.http
      .post<BillingResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        request,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
