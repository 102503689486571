import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersRepository } from 'src/app/core/repositories/user/UsersRepository';
import { environment } from 'src/environments/environment';
import {
  DeleteUserRequestDTO,
  DeleteUserResponseDTO,
  FetchUserRequestDTO,
  FetchUserResponseDTO,
  ListUsersDTO,
  UpdateUserRequestDTO,
  UpdateUserResponseDTO,
} from '../../dto/UserDTO';

@Injectable({
  providedIn: 'root',
})
export class UsersRestRepository extends UsersRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listUsers(): Observable<ListUsersDTO> {
    const endpoint = environment.list_users_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListUsersDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteUser(request: DeleteUserRequestDTO): Observable<DeleteUserResponseDTO> {
    const endpoint = environment.delete_user_endpoint;
    const id = request.idUser;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<DeleteUserResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchUser(id: FetchUserRequestDTO): Observable<FetchUserResponseDTO> {
    const endpoint = environment.fetch_user_endpoint;
    const user = id.idUser;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<FetchUserResponseDTO>(
        `${environment.url_base}/${endpoint}/${user}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateUser(request: UpdateUserRequestDTO): Observable<UpdateUserResponseDTO> {
    const endpoint = environment.update_user_endpoint;
    const id = request.idUser;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<UpdateUserResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
