import { Observable } from 'rxjs';
import {
  ListDigitalSocioDTO,
  ListInvoicesAdminRequestDTO,
  ListInvoicesAdminResponseDTO,
  ListInvoicesRequestDTO,
  ListInvoicesResponseDTO,
  ListInvoicesUserRequestDTO,
  ListInvoicesUserResponseDTO,
  ListScreenDTO,
  ListScreenDigitalRequestDTO,
  ListScreenDigitalDTO,
  UploadFileRequestDTO,
  UploadFileResponseDTO,
  AcceptInvoicesDigitalRequestDTO,
  AcceptInvoicesDigitalResponseDTO,
  AcceptInvoicesUserRequestDTO,
  AcceptInvoicesUserResponseDTO,
  ListInvoicesAdminUserRequestDTO,
  ListInvoicesAdminUserResponseDTO
} from 'src/app/data/dto/InvoicesDTO';

export abstract class InvoiceRepository {
  abstract listInvoices(
    request: ListInvoicesRequestDTO
  ): Observable<ListInvoicesResponseDTO>;
  abstract uploadFileInvoice(
    request: UploadFileRequestDTO
  ): Observable<UploadFileResponseDTO>;
  abstract listUserInvoices(
    request: ListInvoicesUserRequestDTO
  ): Observable<ListInvoicesUserResponseDTO>;
  abstract listDigitalSocio(): Observable<ListDigitalSocioDTO>;
  abstract listScreen(): Observable<ListScreenDTO>;
  abstract listScreenDigital(
    request: ListScreenDigitalRequestDTO
  ): Observable<ListScreenDigitalDTO>;
  abstract listAdminInvoices(
    request: ListInvoicesAdminRequestDTO
  ): Observable<ListInvoicesAdminResponseDTO>;
  abstract acceptInvoicesDigital(
    request: AcceptInvoicesDigitalRequestDTO
  ): Observable<AcceptInvoicesDigitalResponseDTO>;
  abstract acceptInvoicesUser(
    request: AcceptInvoicesUserRequestDTO
  ): Observable<AcceptInvoicesUserResponseDTO>;
  abstract listAdminUserInvoices(
    request: ListInvoicesAdminUserRequestDTO
    ): Observable<ListInvoicesAdminUserResponseDTO>;
}
