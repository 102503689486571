import {
  ListCampaignScreenHistoricalUserRequestDTO,
  ListCampaignScreenHistoricalUserResponseDTO,
  ListCampaignsForScreensBarDTO,
  ListCampaignsScreenHistoricalDigitalSocioRequestDTO,
  ListCampaignsScreenHistoricalDigitalSocioResponseDTO,
  ListCampaignsStateScreensUserRequestDTO,
  ListCampaignStateScreenlUserResponseDTO,
  ListCampaniaDTO,
  ListDigitalSocioDTO,
  ListEstadosDTO,
  ListHistoricalStickerMediaUserRequestDTO,
  ListHistoricalStickerMediaUserResponseDTO,
  ListMonthlyIncomeReportByScreensAdminDTO,
  ListScreenCampaignsDTO, ListScreenDTO, ListStickersMonthlyCampaigntReportAdminDTO,
} from './../../../data/dto/ReportsDTO';
import { Observable } from 'rxjs';
import {
  CampaignsMonthScreenReportDigitalSocioDTO,
} from 'src/app/data/dto/ReportsDTO';

export abstract class ReportsAdminRepository {
  abstract monthlyIncomeReport(): Observable<ListMonthlyIncomeReportByScreensAdminDTO>;
  abstract screenCampaingReport(): Observable<ListScreenCampaignsDTO>;
  abstract stickersMonthlyCampaignReport(): Observable<ListStickersMonthlyCampaigntReportAdminDTO>;
  abstract campaignsMonthScreenReport(): Observable<CampaignsMonthScreenReportDigitalSocioDTO>;
  abstract campaignsScreenHistoricalDigitalSocioReport(request: ListCampaignsScreenHistoricalDigitalSocioRequestDTO
  ): Observable<ListCampaignsScreenHistoricalDigitalSocioResponseDTO>;
  abstract campaignScreenHistoricalUserReport(request: ListCampaignScreenHistoricalUserRequestDTO
  ): Observable<ListCampaignScreenHistoricalUserResponseDTO>;
  abstract listScreen(): Observable<ListScreenDTO>;
  abstract listDigitalSocio(): Observable<ListDigitalSocioDTO>;
  abstract listCampaign(): Observable<ListCampaniaDTO>;
  abstract listState(): Observable<ListEstadosDTO>;
  abstract campaignsStateScreensUserReport(request: ListCampaignsStateScreensUserRequestDTO
  ): Observable<ListCampaignStateScreenlUserResponseDTO>;
  abstract historicalStickerMediaUserReport(request: ListHistoricalStickerMediaUserRequestDTO
  ): Observable<ListHistoricalStickerMediaUserResponseDTO>;
  abstract campaignsForScreensBarReport(): Observable<ListCampaignsForScreensBarDTO>;
}
