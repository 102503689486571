import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';
import { InventoryRepository } from 'src/app/core/repositories/inventory/InventoryRepository';
import { environment } from 'src/environments/environment';
import {
  AddComisionRequestDTO,
  AddComisionResponseDTO,
  ListAchievementsRequestDTO,
  ListAchievementsResponseDTO,
  ListAchievementsAdminRequestDTO,
  ListAchievementsAdminResponseDTO,
  ListInventoryAdminRequestDTO,
  ListInventoryAdminResponseDTO,
  ListInventoryRequestDTO,
  ListInventoryResponseDTO,
  PhotosInventoryRequestDTO,
  PhotosInventoryResponseDTO,
  ProgramScreenRequestDTO,
  ProgramScreenResponseDTO,
  RegisterInventoryRequestDTO,
  RegisterInventoryResponseDTO,
  UpdateAvailableRequestDTO,
  UpdateAvailableResponseDTO,
  UploadImgInventoryRequestDTO,
  UploadImgInventoryResponseDTO,
  CampaignOkeyRequestDTO,
  CampaignOkeyResponseDTO,
  ScheduleScreenRequestDTO,
  ScheduleScreenResponseDTO,
  ListScheduleScreenAdminRequestDTO,
  ListScheduleScreenAdminResponseDTO,
  ListScheduleScreenRequestDTO,
  ListScheduleScreenResponseDTO,
  PayStripeRequestDTO,
  PayStripeResponseDTO,
  UpdatePriceRequestDTO,
  UpdatePriceResponseDTO,
} from '../../dto/InventoryDTO';
import {
  AddSpacesAvailableRequestDTO,
  AddSpacesAvailableResponseDTO,
  OKSpacesAvailableRequestDTO,
  OKSpacesAvailableResponseDTO,
  SearchSpacesAvailableRequestDTO,
  SearchSpacesAvailableResponseDTO,
} from '../../dto/SpacesAvailableDTO';

@Injectable({
  providedIn: 'root',
})
export class InventoryRestRepository extends InventoryRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  registerInventoryDigital(
    request: RegisterInventoryRequestDTO
  ): Observable<RegisterInventoryResponseDTO> {
    const endpoint = environment.register_inventory_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<RegisterInventoryResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listInventoryDigital(
    id: ListInventoryRequestDTO
  ): Observable<ListInventoryResponseDTO> {
    const endpoint = environment.list_inventory_endpoint;
    const params = new HttpParams({
      fromObject: {
        usuario: id.usuario,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListInventoryResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadImgInventoryDigital(
    request: UploadImgInventoryRequestDTO
  ): Observable<UploadImgInventoryResponseDTO> {
    const endpoint = environment.upload_photo_inventory_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<UploadImgInventoryResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateAvailableInventory(request: UpdateAvailableRequestDTO): Observable<UpdateAvailableResponseDTO> {
    const endpoint = environment.update_inventory_available_endpoint;
    const id = request.idInventory;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<UpdateAvailableResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listInventoryAdmin(
    id: ListInventoryAdminRequestDTO
  ): Observable<ListInventoryAdminResponseDTO> {
    const endpoint = environment.list_inventory_admin_endpoint;
    const user = id.idUser;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListInventoryAdminResponseDTO>(
        `${environment.url_base}/${endpoint}/${user}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  addComisionScreen(
    request: AddComisionRequestDTO
  ): Observable<AddComisionResponseDTO> {
    const endpoint = environment.add_comision_endpoint;
    const id = request.idScreen;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<AddComisionResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  addSpacesAvailable(
    request: AddSpacesAvailableRequestDTO
  ): Observable<AddSpacesAvailableResponseDTO> {
    const endpoint = environment.add_spaces_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<AddSpacesAvailableResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  searchSpacesAvailable(
    request: SearchSpacesAvailableRequestDTO
  ): Observable<SearchSpacesAvailableResponseDTO> {
    const endpoint = environment.search_spaces_endpoint;
    const params = new HttpParams({
      fromObject: {
        medio: request.medio,
        ubicacion: request.ubicacion,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<SearchSpacesAvailableResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  searchSpacesAdmin(
    request: SearchSpacesAvailableRequestDTO
  ): Observable<SearchSpacesAvailableResponseDTO> {
    const endpoint = environment.search_spaces_admin_endpoint;
    const params = new HttpParams({
      fromObject: {
        medio: request.medio,
        ubicacion: request.ubicacion,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<SearchSpacesAvailableResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  okSpacesAvailable(
    request: OKSpacesAvailableRequestDTO
  ): Observable<OKSpacesAvailableResponseDTO> {
    const endpoint = environment.ok_spaces_endpoint;
    const id = request.idSpace;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<OKSpacesAvailableResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  programScreen(
    request: ProgramScreenRequestDTO
  ): Observable<ProgramScreenResponseDTO> {
    const endpoint = environment.program_screen_endpoint;
    const id = request.idScreen;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ProgramScreenResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  scheduleScreen(
    params: ScheduleScreenRequestDTO
  ): Observable<ScheduleScreenResponseDTO> {
    const endpoint = environment.schedule_screen_endpoint;
    const httpOptions = { headers: this.headers};
    return this.http
      .put<ScheduleScreenResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(params),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listScheduleScreen(
    id: ListScheduleScreenRequestDTO
  ): Observable<ListScheduleScreenResponseDTO> {
    const endpoint = environment.list_schedule_screen_endpoint;
    const params = new HttpParams({
      fromObject: {
        usuario: id.user,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListScheduleScreenResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listScheduleScreenAdmin(
    id: ListScheduleScreenAdminRequestDTO
  ): Observable<ListScheduleScreenAdminResponseDTO> {
    const endpoint = environment.list_schedule_screen_admin_endpoint;
    const params = new HttpParams({
      fromObject: {
        usuario: id.user,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListScheduleScreenResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMyAchievements(
    request: ListAchievementsRequestDTO
  ): Observable<ListAchievementsResponseDTO> {
    const endpoint = environment.list_my_achievements_endpoint;
    const id = request.idUser;
    const params = new HttpParams({
      fromObject: {
        pantalla: request.pantalla,
        mes: request.mes,
        ciudad: request.ciudad,
        cliente: request.cliente,
        comision: request.comision
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListAchievementsResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getMyAchievementsAdmin(
    request: ListAchievementsAdminRequestDTO
  ): Observable<ListAchievementsAdminResponseDTO> {
    const endpoint = environment.list_my_achievements_admin_endpoint;
    const params = new HttpParams({
      fromObject: {
        pantalla: request.pantalla,
        mes: request.mes,
        ciudad: request.ciudad,
        cliente: request.cliente,
        comision: request.comision
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListAchievementsResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getPhotosInventory(
    request: PhotosInventoryRequestDTO
  ): Observable<PhotosInventoryResponseDTO> {
    const endpoint = environment.list_inventory_photos_endpoint;
    const id = request.idUser;
    const params = new HttpParams({
      fromObject: {
        foto: request.foto,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<PhotosInventoryResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  okeyCampaign(
    request: CampaignOkeyRequestDTO
  ): Observable<CampaignOkeyResponseDTO> {
    const endpoint = environment.campaign_ok_endpoint;
    const id = request.idReserva;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<CampaignOkeyResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  payStripe(
    request: PayStripeRequestDTO
  ): Observable<PayStripeResponseDTO> {
    const endpoint = environment.pay_stripe;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<PayStripeResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  UpdatePrice(
    request: UpdatePriceRequestDTO
  ): Observable<UpdatePriceResponseDTO> {
    const endpoint = environment.update_price_endpoint;
    const id = request.idScreen;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<UpdatePriceResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
