import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';
import { InvoiceRepository } from 'src/app/core/repositories/invoice/InvoiceRepository';
import { environment } from 'src/environments/environment';
import {
  ListDigitalSocioDTO,
  ListInvoicesAdminRequestDTO,
  ListInvoicesAdminResponseDTO,
  ListInvoicesRequestDTO,
  ListInvoicesResponseDTO,
  ListInvoicesUserRequestDTO,
  ListInvoicesUserResponseDTO,
  ListScreenDTO,
  ListScreenDigitalRequestDTO,
  ListScreenDigitalDTO,
  UploadFileRequestDTO,
  UploadFileResponseDTO,
  AcceptInvoicesDigitalRequestDTO,
  AcceptInvoicesDigitalResponseDTO,
  AcceptInvoicesUserRequestDTO,
  AcceptInvoicesUserResponseDTO,
  ListInvoicesAdminUserRequestDTO,
  ListInvoicesAdminUserResponseDTO
} from '../../dto/InvoicesDTO';
@Injectable({
  providedIn: 'root',
})
export class InvoiceRestRepository extends InvoiceRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  listInvoices(
    request: ListInvoicesRequestDTO
  ): Observable<ListInvoicesResponseDTO> {
    const endpoint = environment.list_invoices_endpoint;
    const id = request.idUser;
    const params = new HttpParams({
      fromObject: {
        mes: request.mes,
        estado: request.estado,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListInvoicesResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadFileInvoice(
    request: UploadFileRequestDTO
  ): Observable<UploadFileResponseDTO> {
    const endpoint = environment.upload_invoiceFile_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<UploadFileResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listUserInvoices(
    request: ListInvoicesUserRequestDTO
  ): Observable<ListInvoicesUserResponseDTO> {
    const endpoint = environment.list_invoices_user_endpoint;
    const id = request.idUser;
    const params = new HttpParams({
      fromObject: {
        mes: request.mes
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListInvoicesUserResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listAdminUserInvoices(
    request: ListInvoicesAdminUserRequestDTO
  ): Observable<ListInvoicesAdminUserResponseDTO> {
    const endpoint = environment.list_invoices_admin_user_endpoint;
    const params = new HttpParams({
      fromObject: {
        mes: request.mes
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListInvoicesAdminUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listDigitalSocio(): Observable<ListDigitalSocioDTO> {
    const endpoint = environment.list_digitalSocio_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListDigitalSocioDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listScreen(): Observable<ListScreenDTO> {
    const endpoint = environment.list_screens_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListScreenDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listScreenDigital(
    id: ListScreenDigitalRequestDTO
  ): Observable<ListScreenDigitalDTO> {
    const endpoint = environment.list_screens_digital_endpoint;
    const params = new HttpParams({
      fromObject: {
        usuario: id.idUser
      },
    });
    const httpOptions = { headers: this.headers, params};
    return this.http
      .get<ListScreenDigitalDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listAdminInvoices(
    request: ListInvoicesAdminRequestDTO
  ): Observable<ListInvoicesAdminResponseDTO> {
    const endpoint = environment.list_invoices_admin_endpoint;
    const params = new HttpParams({
      fromObject: {
        digital: request.digital,
        pantalla: request.pantalla,
        mes: request.mes,
        status: request.status
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListInvoicesAdminResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  acceptInvoicesDigital(
    request: AcceptInvoicesDigitalRequestDTO
  ): Observable<AcceptInvoicesDigitalResponseDTO> {
    const endpoint = environment.accept_invoices_digital;
    const id = request.idReserva;
    const httpOptions = { headers: this.headers};
    return this.http
      .put<AcceptInvoicesDigitalResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  acceptInvoicesUser(
    request: AcceptInvoicesUserRequestDTO
  ): Observable<AcceptInvoicesUserResponseDTO> {
    const endpoint = environment.accept_invoices_user;
    const id = request.idReserva;
    const httpOptions = { headers: this.headers};
    return this.http
      .put<AcceptInvoicesUserResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
