import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/service/auth.service';
import { MailBoxRepository } from 'src/app/core/repositories/mailbox/MailBoxRepository';
import { environment } from 'src/environments/environment';
import {
  GetFileMailBoxRequestDTO,
  GetFileMailBoxResponseDTO,
  ListCasesDTO,
  ResolveNotesRequestDTO,
  ResolveNotesResponseDTO,
  SendNotesRequestDTO,
  SendNotesResponseDTO,
  UploadFilesRequestDTO,
  UploadFilesResponseDTO,
  ReplyNotesResponseDTO,
  ReplyNotesRequestDTO,
  DigitalNotesRequestDTO,
  DigitalNotesResponseDTO
} from '../../dto/MailBoxNotesDTO';

@Injectable({
  providedIn: 'root',
})
export class MailBoxRestRepository extends MailBoxRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  sendNotesMailBox(
    request: SendNotesRequestDTO
  ): Observable<SendNotesResponseDTO> {
    const endpoint = environment.send_notesmailbox_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<SendNotesResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadFilesMailBox(
    request: UploadFilesRequestDTO
  ): Observable<UploadFilesResponseDTO> {
    const endpoint = environment.upload_filesNotes_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<UploadFilesResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listCasesMailBox(): Observable<ListCasesDTO> {
    const endpoint = environment.list_notes_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListCasesDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  getFileMailBox(
    request: GetFileMailBoxRequestDTO
  ): Observable<GetFileMailBoxResponseDTO> {
    const endpoint = environment.get_files_notesmailbox_endpoint;
    const id = request.idUser;
    const params = new HttpParams({
      fromObject: {
        nota: request.nota,
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<GetFileMailBoxResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  resolveNotes(
    request: ResolveNotesRequestDTO
  ): Observable<ResolveNotesResponseDTO> {
    const endpoint = environment.resolve_notesmailbox_endpoint;
    const id = request.idNote;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ResolveNotesResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  replyNotes(
    request: ReplyNotesRequestDTO
  ): Observable<ReplyNotesResponseDTO> {
    const endpoint = environment.reply_notesmailbox_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<ReplyNotesResponseDTO>(
        `${environment.url_base}/${endpoint}/${21}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  digitalNotes(
    request: DigitalNotesRequestDTO
  ): Observable<DigitalNotesResponseDTO> {
    const endpoint = environment.digital_Notes;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<DigitalNotesResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
