const url = 'https://devslickbackend.gservicio.com';

export const environment = {
  production: true,
  url_base: `${url}/api/v1`,
  url: `${url}`,
  login_endpoint: 'auth',
  register_user_endpoint: 'usuario/registrar',
  recover_user_endpoint: 'usuario/recovery',
  catalog_type_documents_endpoint: 'catalogo/get/tipoDocumentos',
  upload_documents_tax_endpoint: 'documentos/subirArchivo',
  get_catalog_countries_endpoint: 'catalogo/get/paises',
  get_catalog_states_endpoint: 'catalogo/get/estados',
  get_catalog_media_inventory_enpoint: 'catalogo/get/tipoMedioInventario',
  get_catalog_municipality_endpoint: 'catalogo/get/municipios',
  get_catalog_localidad_endpoint: 'catalogo/localidad',
  register_inventory_endpoint: 'inventario/registrar',
  list_inventory_endpoint: 'inventario/listado',
  upload_photo_inventory_endpoint: 'documentos/subirFotoInv',
  update_inventory_available_endpoint: 'inventario/disponible',
  campaign_endpoint: 'campania',
  campaign_user_endpoint: 'campania/user',
  campaign_admin_endpoint: 'campania/campaniaAdmin',
  documents_endpoint: 'documentos',
  campaign_screen_endpoint: 'campania/pantalla',
  close_campaign_endpoint: 'campania/cerrar',
  campaign_ok_endpoint: 'campania/ok',
  list_inventory_admin_endpoint: 'inventario/listado/admin',
  hire_screen_endpoint: 'campania/contratar',
  add_comision_endpoint: 'campania/comision',
  add_spaces_endpoint: 'espacios/registrar',
  search_spaces_endpoint: 'espacios/busqueda',
  search_spaces_admin_endpoint: 'espacios/busquedaAdmin',
  ok_spaces_endpoint: 'espacios/ok',
  program_screen_endpoint: 'pantallas/aceptarPantalla',
  schedule_screen_endpoint: 'pantallas/contratarpantalla',
  send_notesmailbox_endpoint: 'buzon/misnotas',
  upload_filesNotes_endpoint: 'buzon/misnotas/subirArchivo',
  list_notes_endpoint: 'buzon/misnotas/1',
  list_my_achievements_endpoint: 'pantallas/mislogros',
  list_my_achievements_admin_endpoint: 'pantallas/mislogrosAdmin',
  list_invoices_endpoint: 'facturas/buscar',
  upload_invoiceFile_endpoint: 'facturas/subirArchivo',
  list_invoices_user_endpoint: 'facturas/buscarUser',
  list_invoices_admin_user_endpoint: 'facturas/buscarAdminUser',
  billing_endpoint: 'facturas/facturacion',
  list_digitalSocio_endpoint: 'pantallas/digital',
  list_screens_endpoint: 'pantallas',
  list_screens_digital_endpoint: 'pantallas/pantalladigital',
  list_invoices_admin_endpoint: 'facturas/buscarAdmin',
  list_inventory_photos_endpoint: 'campania/pantalla/fotos',
  list_users_endpoint: 'pantallas/usuarios',
  get_files_notesmailbox_endpoint: 'buzon/misnotas/listarArchivos',
  download_files_notesmailbox_endpoint: 'buzon/misnotas/descargarArchivo',
  download_files_campaing_endpoint: 'campania/download',
  download_file_pdf_endpoint: 'pdf',
  download_file_invoices_pdf_endpoint: 'pdf/facturaUser',
  download_file_excel_endpoint: 'pdf/excel',
  resolve_notesmailbox_endpoint: 'buzon/ok',
  reply_notesmailbox_endpoint: 'buzon/misnotas/responderEmail',
  delete_user_endpoint: 'pantallas/eliminarusuario',
  fetch_user_endpoint: 'pantallas/buscarusuario',
  update_user_endpoint: 'pantallas/usuario',
  list_schedule_screen_endpoint: 'pantallas/listarpantalla',
  list_schedule_screen_admin_endpoint: 'pantallas/listarPantallaAdmin',
  pay_stripe: 'pantallas/pagoStripe',
  llave_cliente: 'pk_live_51JnD8SJ8FEctDvqwpWBUbCZID2yfkfz7X7avswTyxWdOTgFs0qo4G1sgKuCEDMef6EAcG8RVCarJgHWzOOevzXaa00JMSE1jF4',
  report_list_digital_socio: 'reportes/listaDigitalSocio',
  report_list_pantallas: 'reportes/listaPantalla',
  report_list_campanias: 'reportes/listaCampania',
  report_list_estados: 'reportes/listaEstados',
  report_admin_income_monthly: 'reportes/reporteAdmin/porpantalla',
  report_admin_stickers_monthly_campaign: 'reportes/reporteAdmin/stickersmonthlycampaign',
  report_admin_campaigns_for_screens_bar: 'reportes/reporteAdmin/campaniaporpantallabarras',
  report_digital_socio_campaigns_month_screen: 'reportes/reporteDigitalSocio/campaniasalmesporpantalla',
  report_digital_socio_campaigns_screen_historical: 'reportes/reportDigital',
  report_user_filtro_campania_mes: 'reportes/reportUserFiltroCampaniaMes',
  report_user_campaign_screen_historical: 'reportes/reportUser',
  report_user_campania_por_mes: 'reportes/reporteUsuario/campaniapormes',
  report_user_investment_month: 'reportes/reporteUsuario/inversionpormes',
  report_user_campaigns_state_month: 'reportes/reporteUsuario/campaniaporestadopormes',
  report_user_sticker_media_month: 'reportes/reporteUsuario/strickersporpantallapormes',
  accept_invoices_digital: 'facturas/ok',
  accept_invoices_user: 'facturas/okUser',
  digital_Notes: 'buzon//misnotas/misNotasDigital',
  llave: 'pk_test_51KYg53HWF0ZyuueZ4iKeqNMF0whut0ggnjNfAyHhxaAhe93iGoZtJv4QkFs5bSAH0aagpNx5O2ckFPyCSMfvc7xs00XBVekfxX',
  update_price_endpoint: 'campania/precio',
};
